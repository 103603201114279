<spinner
  *ngIf="ztService?.zipTableOptions?.showSpinner && ztService?.loading"
  [asOverlay]="true"
  size="medium"
></spinner>

<main class="Table" data-test-id="TableContainer">
  <div
    class="TableHeader"
    [ngClass]="{ TableHeader__Card: !isTableInsideCard }"
    *ngIf="
      ztService?.zipTableOptions?.title ||
      ztService.zipTableOptions?.nonQueryFilters.length > 0
    "
  >
    <div
      class="TableHeader__Actions"
      data-test-id="tableHeaderActions"
      [ngClass]="{
        'TableHeader__Actions--WithoutPadding': isTableInsideCard,
      }"
    >
      @if (ztService.zipTableOptions?.h2Title) {
        <h2
          class="TableHeader__Title"
          data-test-id="tableHeaderTitle"
          [innerHTML]="ztService.zipTableOptions.title"
        ></h2>
      } @else {
        <h1
          class="TableHeader__Title"
          data-test-id="tableHeaderTitle"
          [innerHTML]="ztService.zipTableOptions.title"
        ></h1>
      }

      <table-header-actions
        (actionButtonClicked)="buttonClicked.emit($event)"
      ></table-header-actions>
    </div>

    <table-filters
      class="TableFilters"
      *ngIf="
        ztService?.zipTableOptions?.nonQueryFilters.length > 0 ||
        ztService?.zipTableOptions?.filterOptions
      "
      [ngClass]="
        ztService?.zipTableOptions?.visuals?.filtersOwnRow
          ? 'table__top__filters--new-row'
          : ''
      "
      [filters]="ztService?.zipTableOptions?.nonQueryFilters"
      [filterOptions]="ztService?.zipTableOptions?.filterOptions"
      [setFilterValues]="ztService?.setFilterValues$ | async"
      (customMethod)="customEventMethod($event)"
      (value)="ztService.filterData($event)"
    >
    </table-filters>
    <div class="TableSubtitle" *ngIf="ztService.zipTableOptions.subtitle">
      <p class="t-data">{{ ztService.zipTableOptions.subtitle }}</p>
    </div>

    <div class="TablePrompt" *ngIf="ztService.showPrompt">
      <table-prompt
        [config]="ztService.zipTableOptions.prompts"
        [prompt]="ztService.prompt"
        (promptActionClicked)="promptClicked.emit($event)"
      ></table-prompt>
    </div>
  </div>

  <div
    #tableWrapper
    class="table u-relative MainTable"
    role="table"
    aria-label="Table"
    tabindex="0"
  >
    <ng-container *ngIf="ztService.columns">
      <div role="rowgroup">
        <table-header-row
          (deleteClicked)="deleteClicked.emit(ztService.selectedRow)"
          (editClicked)="editClicked.emit(ztService.selectedRow)"
          (customMethod)="customEventMethod($event)"
          (headerButtonClicked)="headerButtonClicked.emit($event)"
          class="table__header__wrapper"
          [ngClass]="{
            'table__header__wrapper--dark':
              ztService?.zipTableOptions?.visuals?.theme === 'dark',
          }"
          [dataExists]="ztService.dataExists"
          [detailView]="
            ztService.detailEnabled && ztService.detailColumns.length > 0
          "
          [columns]="ztService.columns"
          [options]="ztService?.zipTableOptions"
          (sortEvent)="ztService.sortData($event.column, $event.sortOrder)"
          [grouping]="ztService.groupingEnabled"
          [multiExpandedAllowed]="
            ztService.detailEnabled && ztService?.zipTableOptions?.detail?.multi
          "
          [allExpanded]="ztService.allRowsExpanded"
          (expandAllRows)="ztService.handleExpandingAllRows()"
          [attr.aria-label]="'Column headers'"
          tabindex="0"
          role="row"
        >
        </table-header-row>
      </div>
    </ng-container>

    <div class="table__body" role="rowgroup" #tableBody>
      <ng-container
        *ngIf="
          ztService.dataExists &&
            (!ztService?.zipTableOptions?.showInnerSpinner ||
              !ztService?.loading);
          else nodata
        "
      >
        <ng-container *ngIf="ztService.groupingEnabled">
          <ng-container
            *ngFor="let group of ztService.groupedData; let ind = index"
          >
            <table-group-header-row
              [attr.title]="ztService?.zipTableOptions?.messages?.tooltip"
              class="table__row__wrapper table__row__wrapper--group"
              [ngClass]="{
                tooltip: ztService?.zipTableOptions?.messages?.tooltip,
                'table__row__wrapper--hover':
                  ztService.zipTableOptions?.selection?.enabled,
                'table__row__wrapper--nested': ztService.groupingEnabled,
                'is-expanded': ztService.expandedGroups[group.display],
              }"
              [expanded]="ztService.expandedGroups[group.display]"
              [columns]="ztService.columns"
              (groupClicked)="ztService.toggleExpandingGrouping(group)"
              [grouping]="ztService.groupingEnabled"
              [detailView]="
                ztService.detailEnabled && ztService.detailColumns.length > 0
              "
              [options]="ztService?.zipTableOptions"
              [data]="group"
              role="row"
            >
            </table-group-header-row>

            <ng-container *ngIf="ztService.expandedGroups[group.display]">
              <ng-container
                *ngTemplateOutlet="
                  normalRowSetup;
                  context: {
                    currentData: group.rows,
                    ztService: ztService,
                  }
                "
              >
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!ztService.groupingEnabled">
          <ng-container
            *ngTemplateOutlet="
              normalRowSetup;
              context: {
                currentData: ztService?.currentData,
                ztService: ztService,
              }
            "
          >
          </ng-container>
        </ng-container>
      </ng-container>

      <div
        class="table__body__loader"
        *ngIf="
          ztService?.zipTableOptions?.showInnerSpinner && ztService?.loading
        "
      >
        <div class="loader inner-loader"></div>
      </div>
    </div>

    <ng-template #nodata>
      <div *ngIf="!ztService.loading" class="table__row table__row-nodata">
        <div
          *ngIf="!ztService.zipTableOptions.showApiError"
          class="u-flex u-alignCenter u-justifyCenter u-maxX"
        >
          <div class="table__cell">
            <p class="t-data">
              {{ ztService?.zipTableOptions?.messages?.noData }}
            </p>
          </div>
        </div>
        <div *ngIf="!!ztService.zipTableOptions.showApiError">
          <div class="table__cell">
            <p *ngFor="let error of ztService.apiErrors">
              {{ error.user_error }}
            </p>
          </div>
        </div>
      </div>
    </ng-template>
  </div>

  <ng-template
    #normalRowSetup
    let-ztService="ztService"
    let-currentData="currentData"
  >
    <ng-container *ngFor="let row of currentData; let ind = index">
      <table-generic-row
        (rowClicked)="clickEvent(row, ind)"
        (rowCellClicked)="cellClickedHandler($event)"
        (actionClicked)="deleteClicked.emit($event)"
        [detailView]="
          ztService.detailEnabled && ztService.detailColumns.length > 0
        "
        class="table__row__wrapper"
        [attr.title]="ztService?.zipTableOptions?.visuals?.tooltip"
        [ngClass]="getCSSClasses(row)"
        (expandRow)="ztService.expandRow(ind)"
        [columns]="ztService.columns"
        [grouping]="ztService.groupingEnabled"
        [data]="row"
        [options]="ztService?.zipTableOptions"
        [attr.aria-label]="'Next row'"
        tabindex="0"
        role="row"
      >
      </table-generic-row>

      <ng-container
        *ngIf="
          ztService.detailEnabled &&
          row.expanded &&
          ztService.detailColumns.length > 0
        "
      >
        <ng-container
          *ngTemplateOutlet="
            ztService.zipTableOptions.detail.template || defaultDetailTemplate;
            context: {
              detailColumns: ztService.detailColumns,
              row: row,
            }
          "
        >
        </ng-container>

        <ng-template #defaultDetailTemplate let-row="row">
          <div class="table__row__details" @accordion>
            <ng-container *ngFor="let extraColumn of ztService.detailColumns">
              <div
                *ngIf="!extraColumn.isHidden"
                class="table__row__detail"
                [ngClass]="{ tooltip: extraColumn.tooltip }"
                [attr.title]="extraColumn.tooltip"
              >
                <div class="table__row__detail__label">
                  <p class="t-caption t-bold">{{ extraColumn.name }}</p>
                </div>

                <div class="table__row__detail__value">
                  <p
                    class="t-data"
                    *ngIf="
                      extraColumn.format !== 'STATUS' &&
                      extraColumn.format !== 'ACTIONS' &&
                      extraColumn.format !== 'FILTERED_ACTIONS'
                    "
                  >
                    {{ ztService.getValueIncludingFormat(row, extraColumn) }}
                  </p>
                  <status
                    *ngIf="extraColumn.format === 'STATUS'"
                    [config]="{
                      label: ztService.getValueIncludingFormat(
                        row,
                        extraColumn
                      )['label'],
                      value: ztService.getValueIncludingFormat(
                        row,
                        extraColumn
                      )['value'],
                      statuses: ztService.getValueIncludingFormat(
                        row,
                        extraColumn
                      )['statuses'],
                    }"
                    [status]="
                      ztService.getValueIncludingFormat(row, extraColumn)[
                        'status'
                      ]
                    "
                  >
                  </status>
                  <span
                    *ngIf="extraColumn.format === 'ACTIONS'"
                    class="u-flex u-u-row"
                  >
                    <ng-container *ngFor="let action of extraColumn.actions">
                      <icon
                        *ngIf="ztService.showConditionalActions(action, row)"
                        [name]="action.icon || action"
                        [label]="action.displayText"
                        [config]="{ type: 'link', size: 'medium' }"
                        (click)="
                          handleCellClicked({
                            context: { row: row, col: extraColumn, val: '' },
                            action: action,
                          })
                        "
                        (keydown.enter)="
                          handleCellClicked({
                            context: { row: row, col: extraColumn, val: '' },
                            action: action,
                          })
                        "
                      >
                      </icon>
                    </ng-container>
                  </span>
                  <span
                    *ngIf="extraColumn.format === 'FILTERED_ACTIONS'"
                    class="u-flex u-u-row"
                  >
                    <icon
                      *ngFor="let action of extraColumn.filteredActions"
                      [name]="action"
                      [config]="{ type: 'link' }"
                      (click)="
                        cellClicked.emit({
                          context: { row: row, col: extraColumn, val: '' },
                          action: action,
                        })
                      "
                      (keydown.enter)="
                        cellClicked.emit({
                          context: { row: row, col: extraColumn, val: '' },
                          action: action,
                        })
                      "
                    >
                    </icon>
                  </span>
                  <span
                    *ngIf="extraColumn.format === 'CONDITIONAL_ACTION'"
                    class="u-flex u-u-row"
                  >
                    <icon
                      *ngIf="
                        extraColumn.conditionalAction.conditionValues.includes(
                          row[extraColumn.conditionalAction.conditionProp]
                        )
                      "
                      [name]="extraColumn?.conditionalAction?.icon"
                      [config]="{ type: 'link' }"
                      [label]="extraColumn?.conditionalAction?.displayText"
                      (click)="
                        cellClicked.emit({
                          context: { row: row, col: extraColumn, val: '' },
                          action: extraColumn.action,
                        })
                      "
                      (keydown.enter)="
                        cellClicked.emit({
                          context: { row: row, col: extraColumn, val: '' },
                          action: extraColumn.action,
                        })
                      "
                    >
                    </icon>
                  </span>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
    @if (ztService?.zipTableOptions?.showSummaryRow) {
      <table-summary-row
        class="table__row__wrapper table__row__wrapper--group"
        [columns]="ztService.columns"
        [data]="currentData"
      ></table-summary-row>
    }
  </ng-template>

  <table-footer
    (page)="ztService.setPage($event)"
    [length]="ztService.getCurrentPageCount"
    [options]="ztService?.zipTableOptions"
    [pageIndex]="ztService?.zipTableOptions?.paging?.pageIndex"
  >
  </table-footer>
</main>
