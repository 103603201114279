import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { ConfigService } from './config.service';
import { GlobalConfig } from '../../app.constants';
import { configKeys } from '../../provider-portal/provider-portal.constant';

const { version } = require('../../../../../../package.json');

@Injectable({
  providedIn: 'root',
})
export class SentryErrorHandlerService implements ErrorHandler {
  private useSentry = true;

  constructor(private configService: ConfigService) {
    const globalConfig: GlobalConfig =
      this.configService.getPageConfig<GlobalConfig>(configKeys.global);

    this.useSentry = !!globalConfig?.analytics?.sentry;

    if (this.useSentry) {
      const dsn =
        globalConfig?.analytics?.sentry?.dsn ??
        'https://9f03310db4ed4aecb56c3533fc03328e@sentry.zipari.net/31';

      Sentry.init({
        dsn,
        release: version,
        denyUrls: [/local\.zipari/],
        integrations: [Sentry.breadcrumbsIntegration({ console: false })],
      });
    }
  }

  handleError(error) {
    if (this.useSentry) {
      Sentry.captureException(error.originalError || error);
    }

    throw error;
  }
}
