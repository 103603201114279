import { configs as tenantConfigs } from '../configuration';

const defaultEnvConfigs = {
  indexRoute: 'provider-portal',
  useLocalConfig: useLocalConfig,
};

const configs = Object.assign(
  defaultEnvConfigs,
  tenantConfigs['provider-portal']
);

export const environment = {
  app: 'provider-portal',
  configs: configs,
  production: true,
};
