<div class="table__row">
  <div class="table__row__expand">
    <icon
      [name]="expanded ? 'chevron_up' : 'chevron_down'"
      [config]="{ type: 'pointer' }"
      (click)="groupClicked.emit()"
    >
    </icon>
  </div>

  <table-generic-cell
    class="table__cell"
    role="cell"
    *ngFor="let column of _columns"
    [column]="column"
    [data]="data.groupRow"
  >
  </table-generic-cell>
</div>
