<div
  class="u-flex u-alignCenter"
  (click)="onClick($event)"
  (keydown.space)="onClick($event)"
  (keydown.enter)="onClick($event)"
  [attr.aria-label]="'ColumnConfig ' + column.name"
  tabindex="0"
>
  <p class="t-data t-bold">{{ column.name || column.prop }}</p>

  <span
    *ngIf="stackedIcons || (column.stackedIcons && !stackedIcons)"
    class="sort--container"
    [ngClass]="{ disabled: !dataExists }"
    [attr.data-test-id]="
      'Sort order: ' + sortOrder === 'asc'
        ? 'ascendingSortContainer'
        : 'descendingSortContainer'
    "
    [attr.aria-label]="
      'Sort order: ' + sortOrder === 'asc' ? 'ascending' : 'descending'
    "
    tabindex="0"
    (keydown.space)="onClick($event)"
    (keydown.enter)="onClick($event)"
    role="button"
  >
    <icon
      *ngIf="column.sortable"
      class="sort sort--up"
      name="carat_up"
      [config]="{ type: 'pointer' }"
      [ngClass]="{
        'sort--active': iconSortActive('asc'),
        'sort--inactive': iconSortActive('desc'),
      }"
    >
      carat_up
    </icon>

    <icon
      *ngIf="column.sortable"
      class="sort sort--down"
      name="carat_down"
      [config]="{ type: 'pointer' }"
      [ngClass]="{
        'sort--active': iconSortActive('desc'),
        'sort--inactive': iconSortActive('asc'),
      }"
    >
      carat_down
    </icon>
  </span>

  <span
    *ngIf="!stackedIcons && !column.stackedIcons"
    [ngClass]="{ disabled: !dataExists }"
  >
    <icon
      *ngIf="column.sortable && sorts && sorts[column.prop]"
      [name]="sortOrder === 'asc' ? 'chevron_up' : 'chevron_down'"
      [config]="{ type: 'pointer' }"
      [attr.data-test-id]="'chevronIcon'"
      [attr.aria-label]="
        'Sort order: ' + sortOrder === 'asc' ? 'ascending' : 'descending'
      "
      tabindex="0"
      (keydown.space)="onClick($event)"
      (keydown.enter)="onClick($event)"
      role="button"
    >
      {{ sortOrder === 'asc' ? 'chevron_up' : 'chevron_down' }}
    </icon>
  </span>

  <span
    *ngIf="column?.columnHeaderIcon; let icon"
    class="Header__ColumnIcon"
    [ngClass]="{ disabled: !dataExists }"
  >
    <icon
      tabindex="0"
      role="button"
      [attr.aria-label]="icon"
      [attr.data-test-id]="column.columnHeaderIconLabel + 'Icon'"
      [label]="column.columnHeaderIconLabel"
      [config]="{ type: 'link' }"
      [name]="icon"
      (click)="headerButtonClicked.emit($event)"
    >
    </icon>
  </span>

  <ng-content></ng-content>
</div>
