<div
  (click)="handleClick()"
  class="table__row u-relative"
  [ngClass]="{
    'multiple-select': ztService.zipTableOptions.selection.multiple,
    'is-selected': selected && options?.selection?.border,
  }"
>
  <div *ngIf="detailView" class="table__row__expand">
    <icon
      [name]="data.expanded ? 'chevron_up' : 'chevron_down'"
      [config]="{ type: 'pointer' }"
      (click)="data.expanded = !data.expanded"
    >
    </icon>
  </div>

  <ng-container *ngIf="ztService.zipTableOptions.selection.multiple">
    <input
      [checked]="selected"
      class="form-control form-control--checkbox table--checkbox"
      type="checkbox"
      (change)="markRowAsSelected($event)"
    />
  </ng-container>

  <ng-container *ngIf="columns && data">
    <ng-container *ngFor="let column of columns">
      <table-generic-cell
        class="table__cell"
        role="cell"
        *ngIf="!column.isHidden"
        [column]="column"
        [data]="data"
        [ngStyle]="{ 'justify-content': column.cell_alignment }"
        (checkboxChecked)="setCheckboxCheckedForRadio($event)"
        (cellClicked)="rowCellClicked.emit($event)"
        (cellActionClicked)="actionClicked.emit($event)"
        [ngClass]="getCSSClasses(column)"
      >
      </table-generic-cell>
    </ng-container>
  </ng-container>

  <div
    class="table__row__indicator"
    [ngClass]="{ 'is-active': selected && options?.selection?.arrow }"
    *ngIf="options?.selection?.arrow"
    (click)="$event.preventDefault(); $event.stopPropagation()"
  >
    <icon name="arrow_right"></icon>
  </div>
</div>
