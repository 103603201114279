<div class="Slider__Container">
  <mat-slider
    discrete
    class="Slider"
    [min]="config.min"
    [max]="config.max"
    [step]="config.step"
    [displayWith]="formatLabel"
    [disabled]="config.isDisabled || null"
    [attr.aria-label]="config?.prop || config.type"
    [attr.disabled]="config?.isDisabled || null"
    [id]="config?.prop || config.type"
  >
    @if (!config.useRangeSlider) {
      <input
        matSliderThumb
        class="Slider__Input"
        (valueChange)="onValueChange($event)"
      />
    } @else {
      <input
        matSliderStartThumb
        class="Slider__Input"
        (valueChange)="onStartValueChange($event)"
      />
      <input
        matSliderEndThumb
        class="Slider__Input"
        (valueChange)="onEndValueChange($event)"
      />
    }
  </mat-slider>
  <div class="Slider__RangeLabels">
    <span class="Slider-RangeLabel--Min"
      >{{ config.prefix }}{{ config.min }}</span
    >
    <span class="Slider-RangeLabel--Max"
      >{{ config.prefix }}{{ config.max }}</span
    >
  </div>
</div>
