import { Component, Input } from '@angular/core';
import { Datagrid, DatagridColumn } from '@zipari/shared-ds-util-datagrid';
import { FormattingService } from '../../../shared/services/formatting.service';

@Component({
  selector: 'datagrid-summary-row',
  templateUrl: './datagrid-summary-row.component.html',
  styleUrl: './datagrid-summary-row.component.scss',
})
export class DatagridSummaryRowComponent {
  @Input() rows: object[];
  @Input() config: Datagrid;

  constructor(private formattingService: FormattingService) {}

  getColumnWidth(column: DatagridColumn) {
    return column.width && this.rows.length > 0 && column.width;
  }

  getTotalValue(items, column) {
    const unformattedValue = items.reduce((accumulator, currentItem) => {
      const currentValue = currentItem[column?.prop];

      if (typeof currentValue === 'number') {
        return accumulator + (currentValue || 0);
      }

      return '--';
    }, 0);

    return typeof unformattedValue === 'number'
      ? this.formattingService.restructureValueBasedOnFormat(
          unformattedValue,
          column,
        )
      : '--';
  }
}
