import { Component, Input } from '@angular/core';
import { TextAreaConfiguration } from '@zipari/shared-ds-util-form';

@Component({
  selector: 'control-textarea',
  templateUrl: './textarea.component.html',
})
export class ControlTextAreaComponent {
  @Input() config: TextAreaConfiguration;
  @Input() control: any;

  get maximumCharactersNumberReached(): boolean {
    const maxLength = this.config.maxLength || 0;

    return maxLength - this.control.value.length <= 0;
  }

  checkMaxLength(event: Event): void {
    const input = event.target as HTMLTextAreaElement;

    if (this.config?.maxLength && input.value?.length > this.config.maxLength) {
      const slicedInputValue = input.value.slice(0, this.config.maxLength);

      this.control.setValue(slicedInputValue);
    }
  }
}
