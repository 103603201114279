@if (config?.accordions) {
  <div>
    <div *ngFor="let accordion of config?.accordions">
      <accordion
        [header]="accordion.header"
        [card]="false"
        [content]="accordionsTemplate"
      ></accordion>

      <ng-template #accordionsTemplate>
        @for (field of accordion.accordionFields; track field) {
          <div class="accordion-content">
            <div class="t-bold link-item">
              <div class="link-item__title" (click)="handleLinkClick(field)">
                @if (field.icon) {
                  <i
                    class="icon icon--medium link-item__icon"
                    [ngStyle]=""
                    [ngClass]=""
                    >{{ field.icon }}
                  </i>
                }
                <p class="t-bold t-link">{{ field.title }}</p>
              </div>
              <div class="link-item__desc">
                <p [innerHTML]="field.description"></p>
              </div>
            </div>
          </div>
        }
      </ng-template>
    </div>
  </div>
} @else {
  <spinner size="medium" flexCenter="true"></spinner>
}
