import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatLegacyCheckbox as MatCheckbox } from '@angular/material/legacy-checkbox';

import { CheckboxOption } from './checkbox-list.model';
import { ExpandingArticleConfig } from '../expanding-article/expanding-article.model';
import { checkInputsForText } from '../../design-system.helper';
import { defaultExpandingTextConfig } from './checkbox-list.constant';

@Component({
  selector: 'checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss'],
})
export class CheckboxListComponent implements OnInit {
  @Input() showSelectAll = true;
  @Input() config;
  @Input() checkboxOptions: CheckboxOption[];
  @Input() layout = 'vertical';

  @Output() selectedOptionList = new EventEmitter();

  @ViewChild('selectCheckbox') selectCheckbox: MatCheckbox;

  areAllOptionChecked = false;
  public expandingTextConfig: ExpandingArticleConfig;

  constructor(private changeDetection: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.expandingTextConfig =
      this.config?.expandingTextConfig || defaultExpandingTextConfig;

    const [config, checkboxOptions] = checkInputsForText([
      this.config,
      this.checkboxOptions,
    ]);

    this.config = config;
    this.checkboxOptions = checkboxOptions;
    this.verifyAllOptionsChecked();
  }

  /**
   * Sets option status as per selection
   * @param event
   * @param option
   */
  setOptionCheckedStatus(event, option: CheckboxOption): void {
    this.applyCheckedStatus(event, option);
    this.verifyAllOptionsChecked();
    this.emitSelectedOptions();
  }

  /**
   * Sets input option as checked if selected manually
   * @param event
   * @param option
   */
  applyCheckedStatus(event, option: CheckboxOption): void {
    // value should be unique for individual option
    this.checkboxOptions.map((data: CheckboxOption) => {
      if (data.value === option.value) {
        data.defaultChecked = event.checked;
      }

      return data;
    });
    this.changeDetection.detectChanges();
  }

  /**
   * Verify to check whether all options selected or not for the "Select All" checkbox
   */
  verifyAllOptionsChecked(): void {
    this.areAllOptionChecked = this.checkboxOptions.every(
      (option: CheckboxOption) => option.defaultChecked && !option.disabled,
    );
  }

  /**
   * Check whether it fullfills indeterminate state condition or not for the "Select All" checkbox
   * @returns indeterminate state boolean value
   */
  getIndeterminateStatus(): boolean {
    return (
      !this.areAllOptionChecked &&
      this.checkboxOptions.filter(
        (option: CheckboxOption) => option.defaultChecked && !option.disabled,
      ).length > 0
    );
  }

  /**
   * Checks all option on select all condition
   * @param checkedStatus
   */
  checkAllOptions(): void {
    // If it is indeterminate state then set selectCheckbox status to false
    if (this.getIndeterminateStatus()) {
      this.selectCheckbox.checked = false;
    }
    // Select all options on sel
    this.checkboxOptions?.map((option: CheckboxOption) => {
      if (!option.disabled) {
        option.defaultChecked = this.selectCheckbox.checked;
      }

      return option;
    });
    this.emitSelectedOptions();
    // To avoid error which were getting while forcefully changing selectAll checkbox status
    this.changeDetection.detectChanges();
  }

  /**
   * Calls function to check indeterminate status for checkbox options
   */
  checkForIndeterminateStatus(): void {
    this.verifyAllOptionsChecked();
    if (!this.areAllOptionChecked && this.selectCheckbox) {
      this.selectCheckbox.checked = this.getIndeterminateStatus();
    }
  }

  /**
   * Emits all selected options list
   */
  emitSelectedOptions(): void {
    const optionList: CheckboxOption[] = this.checkboxOptions.filter(
      (option: CheckboxOption) => option.defaultChecked && !option.disabled,
    );

    this.selectedOptionList.emit(optionList);
  }
}
