/* eslint-disable @angular-eslint/no-conflicting-lifecycle */

import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import {
  AddressLabels,
  ComponentTypes,
  ModelAttributeConfig,
  ModelAttributeDirections,
} from '@zipari/shared-ds-util-configuration';
import { formatTypes } from '@zipari/shared-ds-util-format';
import { ObjectStatuses, StatusType } from '@zipari/shared-ds-util-status';
import { FormattingService } from '../../shared/services/formatting.service';
import { getValue } from '../../shared/utils/get-value';
import { enumToArray } from '../../shared/utils/object';
import { stringBuilder } from '../../shared/utils/string-builder';
import { BadgeConfig } from '../badge/badge.constants';
import { checkInputsForText } from '../../design-system.helper';

@Component({
  selector: 'model-attribute',
  templateUrl: './model-attribute.component.html',
  styleUrls: ['./model-attribute.component.scss'],
})
export class ModelAttributeComponent implements OnInit, OnChanges, DoCheck {
  @Input() config: ModelAttributeConfig;
  @Input() context: object = {};
  @Input() format = true;
  @Input() statuses: ObjectStatuses;
  @Input() addressLabels: AddressLabels[];
  @Output() linkClicked = new EventEmitter<any>();

  statusType = StatusType;
  label: string;
  value: any;
  text: string;
  route: string;
  displayType: formatTypes;
  displaySubtype: string;
  displayTypes = formatTypes;
  prevValue: any;
  isHidden = false;
  badgeStatus: StatusType = null;
  typesWithNoDefaultLabel = [formatTypes.ADDRESSES];
  componentTypesWithNoDefaultLabel = [ComponentTypes.badge];
  componentTypes = ComponentTypes;

  constructor(
    public formattingService: FormattingService,
    private elRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  directionValue = ModelAttributeDirections.column;

  public get direction() {
    return this.directionValue;
  }

  @Input()
  public set direction(direction) {
    this.directionValue = Object.values(ModelAttributeDirections).includes(
      direction,
    )
      ? direction
      : ModelAttributeDirections.column;
  }

  public get configValue() {
    if (!['', null, undefined].includes(this.config.value)) {
      return this.config.value;
    } else {
      // eslint-disable-next-line no-useless-escape
      return `\$\{${this.config.prop}\}`;
    }
  }

  public get valueIsList() {
    if (!Array.isArray(this.value)) {
      return false;
    }

    return (
      this.value.length > 0 &&
      this.value.every((item) => item !== '') &&
      this.value.some((item) => typeof item === 'string')
    );
  }

  get badgeConfig(): BadgeConfig {
    return {
      ...this.config,
      value: this.value,
      status: this.badgeStatus,
      icon: null,
      iconName: '',
    };
  }

  get isLabelExcludedForThisFormat() {
    return this.typesWithNoDefaultLabel.includes(this.displayType);
  }

  get isLabelExcludedForThisComponent() {
    return this.componentTypesWithNoDefaultLabel.includes(
      this.config.componentType,
    );
  }

  ngOnInit() {
    const [config, context, statuses, addressLabels] = checkInputsForText([
      this.config,
      this.context,
      this.statuses,
      this.addressLabels,
    ]);

    this.config = config as ModelAttributeConfig;
    this.context = context;
    this.statuses = statuses;
    this.addressLabels = addressLabels as AddressLabels[];
    this.setupModelAttributeConfig();
  }

  /* istanbul ignore next */
  ngOnChanges() {
    this.setupModelAttributeConfig();
    if (this.config.hideIfZeroOrNull) {
      this.valueIsGreaterThanZero();
    }
  }

  ngDoCheck() {
    if (this.config.value !== this.prevValue) {
      this.prevValue = this.config.value;
      this.setupModelAttributeConfig();
    }
  }

  setupModelAttributeConfig() {
    this.updateLabelAndValue();
    this.determineDisplayType();
    this.determineDisplaySubtype();
    this.checkIfBadgeType();
  }

  applyFormatting() {
    if (this.format) {
      const reg = new RegExp(/\$\{(.+)\}/, 'gm');

      this.value = reg.test(this.configValue)
        ? stringBuilder(this.configValue, this.context)
        : this.configValue;

      if (
        this.value &&
        typeof this.value === 'string' &&
        this.value.includes('[object Object]')
      ) {
        this.value = getValue(this.context, this.config.prop);
      }

      this.value = this.formattingService.restructureValueBasedOnFormat(
        this.value,
        this.config,
      );

      if (this.config?.subformat) {
        for (let i = 0; i < this.value.length; i++) {
          this.value[i] = this.formattingService.restructureValueBasedOnFormat(
            this.value[i],
            this.config,
            this.config.subformat,
          );
        }
      }

      const hide = 'HIDE';
      const isEmpty =
        !this.value || !this.value.toString().replace(/\s/g, '').length;

      if ((isEmpty || this.value === hide) && this.config.noValueMessage) {
        this.value = this.config.noValueMessage;
        this.isHidden = this.value === hide;
      }
    } else {
      this.value = this.config.value || this.config.noValueMessage || 'N/A';
    }
  }

  updateLabelAndValue() {
    this.prevValue = this.config.value;
    this.value = this.config.value;
    this.text = this.config.text;
    this.route = this.config.route;
    this.applyFormatting();
    this.label = stringBuilder(this.config.label || '', this.context);

    if (!this.context && this.config.context) {
      this.value = this.config.noValueMessage || 'N/A';
    }
  }

  determineDisplaySubtype() {
    if (this.config) {
      const parsedConfig = this.config.subformat;

      this.displaySubtype = this.setFormat(parsedConfig);
    }
  }

  determineDisplayType() {
    if (this.config) {
      let parsedConfig = this.config.format;
      // Make Format Array with 'LINK' to be `LINK` Type

      if (
        Array.isArray(this.config.format) &&
        this.config.format.includes(formatTypes.LINK)
      ) {
        parsedConfig = formatTypes.LINK;
      }
      this.displayType = this.setFormat(parsedConfig);
    }
  }

  setFormat(parsedConfig: any) {
    let format: any;

    switch (parsedConfig) {
      case formatTypes.LINK:
        // this then gets handled as an angular route within the application
        format = this.configValue?.includes('http')
          ? 'EXTERNAL_LINK'
          : parsedConfig;
        break;
      case formatTypes.LIST:
        format = parsedConfig;

        if (!Array.isArray(this.value)) {
          format = 'DEFAULT';
        }
        break;
      case formatTypes.ADDRESS:
      case formatTypes.ADDRESSES:
      case formatTypes.BOOLEAN_STRING_MAP:
      case formatTypes.EMAIL:
      case formatTypes.EXTERNAL_LINK:
      case formatTypes.PHONE:
      case formatTypes.PHONE_AND_TYPE:
      case formatTypes.STATIC_GRID:
      case formatTypes.STATUS:
      case formatTypes.VALUE_MAP:
        format = parsedConfig;
        break;
      default:
        format = 'DEFAULT';
        break;
    }

    return format;
  }

  checkIfBadgeType() {
    if (
      this.config.componentType === ComponentTypes.badge &&
      this.config.statusProp
    ) {
      this.getBadgeStatus(
        getValue(this.context, this.config.statusProp).toLowerCase(),
      );
    }
  }

  getBadgeStatus(label) {
    if (this.statuses) {
      const types = enumToArray(this.statusType);

      types.forEach((type) => {
        if (
          this.statuses[type.id] &&
          (this.statuses[type.id]
            .map((status) => status['value'].toLowerCase())
            .includes(label) ||
            this.statuses[type.id]
              .map((status) => status['label'].toLowerCase())
              .includes(label))
        ) {
          this.badgeStatus = StatusType[type.name];
        }
      });
    } else {
      this.badgeStatus = StatusType.EXPIRED;
    }
  }

  valueIsGreaterThanZero(): boolean {
    if (!this.config.allowZeroValue) {
      const value = this.value.replace(/\D/g, '');

      if (value <= 0) {
        this.setDisplayNone();
      }

      return value > 0;
    }

    return true;
  }

  setDisplayNone() {
    this.renderer.setStyle(this.elRef.nativeElement, 'display', 'none');
  }

  onLinkClicked() {
    this.linkClicked.emit({ ...this.config, route: this.value });
  }

  getAddressLabel(context, idx): string | null {
    const addressType = context?.addresses?.[idx]?.address_type;
    const object = this.addressLabels?.find((obj) => obj.prop === addressType);

    return object?.label || null;
  }
}
