<ng-template #noData>
  <div class="u-flex u-justifyCenter">
    <div class="datagrid__no__data" data-test-id="datagridNoData">
      <ng-container *ngIf="config.noData; else defaultMessage">
        <div>
          <img
            *ngIf="config.noData.imageUrl"
            [attr.alt]="config.noData?.message"
            [src]="config.noData.imageUrl"
            [style.width]="config.noData?.imageWidth"
          />

          <p
            *ngIf="config.noData.message; else defaultMessage"
            class="ui-description"
            [innerHTML]="config.noData.message"
            data-test-id="datagridNoDataMessage"
          ></p>
        </div>
      </ng-container>
      <ng-template #defaultMessage>
        <p class="ui-description" tabindex="0">No data found.</p>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #error>
  <div class="u-flex u-justifyCenter">
    <div class="datagrid__no__data" data-test-id="datagridError">
      <ng-container *ngIf="config.error; else defaultMessage">
        <div>
          <img
            aria-label="Error Image"
            alt="Error Image"
            *ngIf="config.error.imageUrl"
            [src]="config.error.imageUrl"
          />

          <p
            tabindex="0"
            *ngIf="config.error.message; else defaultMessage"
            class="ui-description"
            [innerHTML]="config.error.message"
            data-test-id="datagridErrorMessage"
          ></p>
        </div>
      </ng-container>
      <ng-template #defaultMessage>
        <p tabindex="0" class="ui-description">
          Sorry, we’re having tech troubles on our end. Try refreshing the page
          or checking back later.
        </p>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template
  #cellOptions
  let-rowIndex="index"
  let-row="row"
  let-column="column"
  role="table"
  aria-rowcount="81"
>
  <ng-container *ngIf="column.format === formats.DOWNLOAD">
    <icon
      [id]="'action-' + rowIndex + dataGridId"
      name="download"
      [attr.title]="'download'"
      [config]="{ type: 'link' }"
      (click)="onActionClicked(row, 'download', $event)"
      [dataLoadEvent]="digitalDataGridService.getDataLoadDownloadEvent(column)"
      role="button"
      aria-label="Download"
    >
    </icon>
  </ng-container>
  <ng-container *ngIf="column.format === formats.ICON">
    <p
      *ngIf="getFormattedValue(row, column); let value"
      [innerHTML]="value"
    ></p>
    <icon
      [id]="'actionIcon-' + column.action + rowIndex + dataGridId"
      [name]="column.icon"
      [attr.title]="column.action"
      [config]="{ type: 'link' }"
      (click)="onActionClicked(row, column.action, $event)"
      (keydown.space)="onActionClicked(row, column.action, $event)"
      tabindex="0"
      [dataLoadEvent]="digitalDataGridService.getDataLoadDownloadEvent(column)"
      [attr.aria-label]="column.label"
    >
    </icon>
  </ng-container>
  <ng-container *ngIf="column.format === formats.ICON_ONLY && row[column.prop]">
    <icon
      [id]="'actionIcon-' + column.action + rowIndex + dataGridId"
      [name]="column.icon"
      [label]="column.label"
      [attr.title]="column.action"
      [config]="{ type: 'link' }"
      (click)="onActionClicked(row, column.action, $event)"
      tabindex="0"
      [dataLoadEvent]="digitalDataGridService.getDataLoadDownloadEvent(column)"
      (keydown.space)="onActionClicked(row, column.action, $event)"
      [attr.aria-label]="column.label"
    >
    </icon>
  </ng-container>
  <div
    *ngIf="column.format === formats.ACTION_ICONS"
    class="datagrid__cell__actions"
  >
    <ng-container *ngFor="let actionIcon of column.actionIcons; let index">
      <icon
        *ngIf="
          !actionIcon.conditions ||
          (row | zipConditional: actionIcon.conditions)
        "
        [name]="actionIcon.icon"
        [id]="'actionIcon-' + actionIcon.action + index"
        [attr.title]="actionIcon.action"
        [config]="{ type: 'link' }"
        (click)="onActionClicked(row, actionIcon.action, $event)"
        (keydown.space)="onActionClicked(row, actionIcon.action, $event)"
        [dataLoadEvent]="
          digitalDataGridService.getDataLoadDownloadEvent(column)
        "
        tabindex="0"
      >
      </icon>
    </ng-container>
  </div>

  <div
    *ngIf="getTypeOf(getFormattedValue(row, column)) === 'object'"
    class="datagrid__cell__actions"
  >
    <ng-container *ngIf="column.format === 'TEXTWITHICON'">
      <lib-notes-text-with-icon
        [data]="row[column.prop]"
      ></lib-notes-text-with-icon>
    </ng-container>
  </div>

  <ng-container *ngIf="column.format === formats.DATE_RANGE">
    <p tabindex="0" class="ui-description">
      <span>{{
        getFormattedValue(row, { format: formats.DATE, prop: column.startProp })
      }}</span>
      <span>&nbsp;-&nbsp;</span>
      <span>{{
        getFormattedValue(row, { format: formats.DATE, prop: column.endProp })
      }}</span>
    </p>
  </ng-container>

  <ng-container *ngIf="column.format === formats.BUTTON">
    <zip-button
      *ngIf="row.is_cancelable && row.payment_status !== 'Canceled'"
      [config]="config.button"
      (zipButtonClicked)="onActionClicked(row, 'cancel_payment', $event)"
    >
    </zip-button>
  </ng-container>

  <ng-container *ngIf="getFormattedValue(row, column); let value">
    <ng-container [ngSwitch]="column.format">
      <ng-container *ngSwitchCase="formats.STATUS">
        <status
          class="datagrid__status u-flex"
          [ngClass]="{
            'datagrid__status--RightAligned': column.isRightAligned,
          }"
          [config]="{
            hideBackground: config.hideStatusBackground,
            label: getStatusLabel(column, row[column.prop]),
            statuses: column.statuses,
          }"
          data-test-id="DatagridColumnStatus"
        ></status>
      </ng-container>

      <ng-container *ngSwitchCase="formats.LINK">
        <a
          tabindex="0"
          class="t-link Datagrid__link"
          (click)="viewDetail(row, column.prop, column?.routeToProp)"
          (keydown.enter)="viewDetail(row, column.prop, column?.routeToProp)"
          role="link"
          href="javascript:void(0)"
        >
          {{ value }}
        </a>
      </ng-container>

      <ng-container *ngSwitchCase="formats.SSO_LINK">
        <a
          tabindex="0"
          class="t-link"
          (click)="viewSSODetail(row, column.route, column.options)"
          (keydown.enter)="viewSSODetail(row, column.route, column.options)"
          role="link"
          href="javascript:void(0)"
        >
          {{ column.ssoLinkLabel }}

          <icon
            class="Datagrid__SsoIcon"
            [config]="{ type: 'link', size: 'small' }"
            name="open_in_new"
            [attr.title]="'attachment'"
          >
          </icon>
        </a>
      </ng-container>

      <ng-container *ngSwitchCase="formats.LIST">
        <div>
          <p
            tabindex="0"
            class="ui-description"
            *ngFor="let line of row[column.prop]"
            [innerHTML]="line"
          ></p>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="formats.ATTACHMENT">
        <icon name="attachment" [attr.title]="'attachment'"> </icon>
      </ng-container>

      <ng-container *ngSwitchCase="formats.TEXT">
        <p
          tabindex="0"
          class="ui-description"
          [ngClass]="{ 'is-unread': row.is_read === false }"
          [innerHTML]="value || '—'"
        ></p>
      </ng-container>

      <ng-container *ngSwitchCase="formats.BOOLEAN_ICON">
        <icon
          [name]="value"
          [config]="{ fill: value === 'check' ? 'success' : 'error' }"
        ></icon>
      </ng-container>

      <ng-container *ngSwitchCase="formats.EXPANDING_LIST">
        <expanding-list
          [list]="value"
          [config]="{ lineCount: column.limit || limit }"
        ></expanding-list>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <p
          *ngIf="
            column.format !== formats.ICON_ONLY &&
            column.format !== formats.ACTION_ICONS &&
            value.format !== 'TEXT_WITH_TOOLTIP'
          "
          tabindex="0"
          class="ui-description"
          [ngClass]="{
            't-bold': row.is_read === false || column.format === formats.HEADER,
            'is-unread': row.is_read === false,
          }"
        >
          {{ value || '—' }}
        </p>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="value.format === 'TEXT_WITH_TOOLTIP'">
      <p
        *ngIf="column.format !== 'TEXTWITHICON'"
        tabindex="0"
        class="ui-description Datagrid__Description"
        [ngClass]="{ 'is-unread': row.is_read === false }"
        [ngStyle]="{ color: value.color }"
      >
        {{ getFormattedObjectValue(value.value, column, value.type) || '—' }}
      </p>
      <tooltip
        *ngIf="value.tooltip"
        class="Datagrid__TooltipWithText"
        [config]="getFormattedTooltipObjectValue(value, column)"
      ></tooltip>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isClickable(row, condition)">
    <p (click)="onActionClicked(row, column.action, $event)" class="t-link">
      {{ column.link || '' }}
    </p>
  </ng-container>
</ng-template>

<section class="datagrid--mobile">
  <ng-container *ngIf="data && !isLoading; else datagridLoading">
    <ng-container *ngIf="!hasAnErrorOccurred; else error">
      <ng-container *ngIf="data?.length > 0; else noData">
        <div
          class="datagrid--mobile__row"
          *ngFor="
            let row of config.rowLimit
              ? (rows | slice: 0 : config.rowLimit)
              : rows;
            let rowIndex = index
          "
        >
          <div class="datagrid--mobile__cell">
            <div
              class="datagrid--mobile__cell__heading u-flex u-alignCenter u-justifyBetween"
            >
              <h6
                *ngIf="(config?.columns)[0].format !== 'TEXTWITHICON'"
                tabindex="0"
                class="ui-card-header-1"
              >
                <span class="t-bold">{{ (config?.columns)[0].label }} </span>
                <p
                  *ngIf="(config?.columns)[0].valueInNextLine; else headerValue"
                >
                  <ng-container *ngTemplateOutlet="headerValue"></ng-container>
                </p>
                <ng-template #headerValue>{{
                  getFormattedValue(row, (config?.columns)[0])
                }}</ng-template>
              </h6>
              <h6
                *ngIf="(config?.columns)[0].format === 'TEXTWITHICON'"
                tabindex="0"
                class="ui-card-header-1"
              >
                <lib-notes-text-with-icon
                  [data]="row[config?.columns[0].prop]"
                  [isTextBold]="true"
                >
                </lib-notes-text-with-icon>
              </h6>
              <icon
                *ngIf="(config?.columns)[0].format === formats.LINK"
                name="chevron_right"
                [config]="{ type: 'link' }"
                (click)="
                  viewDetail(
                    row,
                    (config?.columns)[0].prop,
                    (config?.columns)[0].routeToProp
                  )
                "
                (keydown.enter)="
                  viewDetail(
                    row,
                    (config?.columns)[0].prop,
                    (config?.columns)[0].routeToProp
                  )
                "
                tabindex="0"
              >
              </icon>
            </div>
          </div>

          <div
            class="datagrid--mobile__cell"
            *ngFor="let column of config?.columns | slice: 1"
          >
            <ng-container
              *ngIf="!isActionableColumn(column.format); else mobileButton"
            >
              <p tabindex="0" class="datagrid--mobile__cell__label">
                {{ column.label }}
              </p>

              <ng-template
                [ngTemplateOutlet]="cellOptions"
                [ngTemplateOutletContext]="{
                  row: row,
                  column: column,
                  index: index,
                }"
              >
              </ng-template>
            </ng-container>

            <ng-template #mobileButton>
              <zip-button
                *ngIf="
                  column.format !== formats.ACTION_ICONS;
                  else actionButtons
                "
                [config]="{
                  content: column.label,
                  icon:
                    column.format === formats.DOWNLOAD
                      ? 'download'
                      : column.icon,
                  level: 'medium',
                  size: 'fullWidth',
                  isIconRight: true,
                }"
                (zipButtonClicked)="
                  onActionClicked(
                    row,
                    column.format === formats.DOWNLOAD
                      ? 'download'
                      : column.action,
                    $event
                  )
                "
                [dataLoadEvent]="
                  digitalDataGridService.getDataLoadDownloadEvent(column)
                "
              >
              </zip-button>
              <ng-template #actionButtons>
                <div
                  *ngFor="let button of column.actionIcons"
                  class="datagrid--mobile__cell__actions"
                >
                  <zip-button
                    [id]="'actionButton-' + button.icon + rowIndex + dataGridId"
                    *ngIf="
                      !button.conditions ||
                      (row | zipConditional: button.conditions)
                    "
                    [config]="{
                      content: button.action,
                      icon: button.icon,
                      level: button.level || 'medium',
                      size: 'fullWidth',
                      isIconRight: !button.isIconLeft && true,
                    }"
                    (zipButtonClicked)="
                      onActionClicked(row, button.action, $event)
                    "
                  >
                  </zip-button>
                </div>
              </ng-template>
            </ng-template>

            <tooltip
              *ngIf="column.tooltipProp"
              class="datagrid__Tooltip"
              [config]="{ description: getFormattedToolTipValue(row, column) }"
            ></tooltip>
          </div>
        </div>
        <div *ngIf="footerDetail" class="Datagrid__FooterDetail">
          <div class="datagrid--mobile__row">
            <div
              class="datagrid--mobile__cell__heading u-flex u-alignCenter u-justifyBetween"
            >
              <h6 tabindex="0" class="ui-card-header-1">
                <p
                  *ngIf="(config?.columns)[0].valueInNextLine; else headerValue"
                >
                  <ng-container *ngTemplateOutlet="headerValue"></ng-container>
                </p>
                <ng-template #headerValue>{{
                  getFormattedValue(footerDetail, (config?.columns)[0])
                }}</ng-template>
              </h6>
            </div>
            <div
              class="datagrid--mobile__cell"
              *ngFor="let column of config?.columns | slice: 1"
            >
              <ng-container>
                <p tabindex="0" class="datagrid--mobile__cell__label">
                  {{ column.label }}
                </p>
                <p tabindex="0">
                  {{ getFormattedValue(footerDetail, column) || '—' }}
                </p>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</section>

<section
  class="datagrid"
  tooltip
  [ngClass]="{ 'is-scrollable': isScrollable }"
  (scroll)="isScrollable && onScroll($event)"
  role="table"
>
  <div
    class="datagrid__shadow datagrid__shadow--left"
    *ngIf="isScrollable"
    #datagridShadowLeft
  ></div>

  <div class="datagrid__header__wrapper" role="rowgroup">
    <div class="datagrid__header" data-test-id="datagridHeader" role="row">
      <div
        class="datagrid__cell datagrid__cell--select"
        *ngIf="config.isSelectable"
        data-test-id="datagridHeaderColumn-0"
        role="columnheader"
      >
        <icon
          [name]="selectIcon"
          [config]="{
            type: 'pointer',
            fill: selectedRows.length > 0 ? 'primary' : 'text--1',
          }"
          (click)="toggleAllRows()"
        >
        </icon>
      </div>

      <ng-template #selectionAction>
        <div class="datagrid__selection">
          <div
            class="datagrid__selection__action"
            (click)="selectionActionClicked.emit(selectedRows)"
            (keydown.enter)="selectionActionClicked.emit(selectedRows)"
          >
            <icon
              [name]="config.selectionAction.icon"
              [config]="{ type: 'link' }"
            >
            </icon>
            <p tabindex="0" class="datagrid__selection__action__label">
              {{ config.selectionAction.label }}
            </p>
          </div>

          <status
            class="datagrid__selection__count"
            [config]="{
              hideBackground: config.hideStatusBackground,
              label: selectedRows.length + ' ' + rowsSelectedStatus,
            }"
            status="success"
          >
          </status>
        </div>
      </ng-template>

      <ng-container *ngIf="selectedRows.length === 0; else selectionAction">
        <div
          class="datagrid__cell datagrid__cell--header"
          [ngClass]="{
            'datagrid__cell--alignLeft': column.isLeftAligned,
            'datagrid__cell--alignRight': column.isRightAligned,
            'datagrid__cell--alignCenter':
              column.isCenterAligned ||
              (!column.isLeftAligned && !column.isRightAligned),
          }"
          [ngStyle]="{
            width: getColumnWidth(column),
            'min-width': getColumnWidth(column),
            'max-width': getColumnWidth(column),
          }"
          *ngFor="let column of config?.columns; let i = index"
          [attr.data-test-id]="'datagridHeaderColumn-' + i"
          role="columnheader"
        >
          <div
            (click)="handleColumnSortByClick($event, column)"
            (keydown.space)="handleColumnSortByClick($event, column)"
            [ngClass]="{ 'is-sortable': column.sortable }"
            *ngIf="showHeaderLabel(column, formats, rows)"
            class="datagrid__cell--header__label"
            tabindex="0"
            [dataLoadEvent]="
              digitalDataGridService.getDataLoadSortEvent(column)
            "
          >
            <p class="Datagrid__CellHeaderValue">{{ column.label }}</p>

            <lib-sort-toggle
              *ngIf="column.sortable"
              [isAscending]="
                (column | datagridSortingState: sortOrder) ===
                sortDirections.ASCENDING
              "
              [isDescending]="
                (column | datagridSortingState: sortOrder) ===
                sortDirections.DESCENDING
              "
            >
            </lib-sort-toggle>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <section class="datagrid__body" #datagridBody>
    <ng-template #datagridLoading>
      <div class="datagrid__loader" data-test-id="datagridDataLoader">
        <preloader size="medium"></preloader>
        <p>Loading data...</p>
      </div>
    </ng-template>
    <section *ngIf="!hasAnErrorOccurred; else error" role="rowgroup">
      <ng-container *ngIf="rows && !isLoading; else datagridLoading">
        <ng-container *ngIf="rows.length > 0; else noData">
          <section
            class="datagrid__row__wrapper"
            *ngFor="
              let row of config.rowLimit
                ? (rows | slice: 0 : config.rowLimit)
                : rows;
              let rowIndex = index
            "
            role="row"
            [attr.aria-rowindex]="rowIndex"
          >
            <div
              class="datagrid__row"
              [ngClass]="{ 'is-clickable': selectableRows }"
              [attr.data-test-id]="'datagridDataRow-' + rowIndex + dataGridId"
            >
              <div
                class="datagrid__cell datagrid__cell--select"
                *ngIf="config.isSelectable"
                role="cell"
              >
                <icon
                  tabindex="0"
                  [name]="isRowSelected(row) ? 'checkbox_active' : 'checkbox'"
                  [config]="{
                    type: 'pointer',
                    fill: isRowSelected(row) ? 'primary' : 'initial',
                  }"
                  (click)="toggleRow($event, row)"
                  (keydown.space)="toggleRow($event, row)"
                >
                </icon>
              </div>

              <div
                class="datagrid__cell"
                [ngClass]="{
                  'datagrid__cell--ellipsis': column.width,
                  'datagrid__cell--alignRight': column.isRightAligned,
                  'datagrid__cell--alignLeft': column.isLeftAligned,
                  'datagrid__cell--alignCenter':
                    column.isCenterAligned ||
                    (!column.isLeftAligned && !column.isRightAligned),
                }"
                [ngStyle]="{
                  width: getColumnWidth(column),
                  'min-width': getColumnWidth(column),
                  'max-width': getColumnWidth(column),
                }"
                *ngFor="let column of config?.columns; let columnIndex = index"
                (click)="datagridRowClicked.emit(row)"
                [attr.data-test-id]="
                  'datagridDataColumn-' +
                  rowIndex +
                  dataGridId +
                  '.' +
                  columnIndex
                "
                role="cell"
              >
                <ng-template
                  [ngTemplateOutlet]="cellOptions"
                  [ngTemplateOutletContext]="{
                    row: row,
                    column: column,
                    index: rowIndex,
                  }"
                >
                </ng-template>
                <tooltip
                  *ngIf="column.tooltipProp"
                  class="datagrid__Tooltip"
                  [config]="{
                    description: getFormattedToolTipValue(row, column),
                  }"
                ></tooltip>
              </div>
            </div>
          </section>
          <datagrid-summary-row [rows]="rows" [config]="config">
          </datagrid-summary-row>
        </ng-container>
      </ng-container>
    </section>
  </section>

  <footer *ngIf="footerDetail" class="Datagrid__FooterWrapper">
    <div class="Datagrid__Footer" data-test-id="datagridFooter">
      <ng-container>
        <div
          class="datagrid__cell"
          [ngClass]="{
            'datagrid__cell--alignLeft': column.isLeftAligned,
            'datagrid__cell--alignRight': column.isRightAligned,
            'datagrid__cell--alignCenter':
              column.isCenterAligned ||
              (!column.isLeftAligned && !column.isRightAligned),
          }"
          [ngStyle]="{
            width: getColumnWidth(column),
            'min-width': getColumnWidth(column),
            'max-width': getColumnWidth(column),
          }"
          *ngFor="let column of config?.columns; let i = index"
          [attr.data-test-id]="'datagridFooterColumn-' + i"
          role="cell"
        >
          <p tabindex="0">
            {{ getFormattedValue(footerDetail, column) || '—' }}
          </p>
        </div>
      </ng-container>
    </div>
  </footer>

  <div
    class="datagrid__shadow datagrid__shadow--right"
    *ngIf="isScrollable"
    #datagridShadowRight
  ></div>
</section>
