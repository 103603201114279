import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { EventInfo } from '@zipari/shared-ds-util-digital-data';
import { ModalConfig, ModalTypes } from '@zipari/shared-ds-util-modal';
import {
  LinkConfig,
  NavigationLinkTargets,
} from '@zipari/shared-ds-util-navigation';
import { LoggerService } from '../../shared/services/logger.service';
import { WindowService } from '../../shared/services/window.service';
import { stopEvent } from '../../shared/utils/event';
import { ExternalSiteModalConfig } from '../external-site-warning-modal/external-site-warning-modal.model';
import { LinkService } from './link.service';
import { checkInputsForText } from '../../design-system.helper';

@Component({
  selector: 'ds-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent implements AfterViewInit, OnInit {
  @Input() linkConfig: LinkConfig;
  @Input() externalSiteModalConfig: ExternalSiteModalConfig;
  @Output() linkClicked = new EventEmitter();
  @Output() openExternalLinkModal = new EventEmitter();

  @ViewChild('content') content: ElementRef;

  isModalOpen = false;
  pageName: string;

  constructor(
    private window: WindowService,
    private router: Router,
    private logger: LoggerService,
    private renderer: Renderer2,
    private linkService: LinkService,
    private location: Location,
  ) {}

  @HostListener('keyup.enter', ['$event'])
  onKeyDownChild(event: Event) {
    this.linkConfig.useExternalSiteModal
      ? this.openModal(event)
      : this.handleLinkClick(event, this.linkConfig);
  }

  ngOnInit(): void {
    const [linkConfig, externalSiteModalConfig] = checkInputsForText([
      this.linkConfig,
      this.externalSiteModalConfig,
    ]);

    this.linkConfig = linkConfig as LinkConfig;
    this.externalSiteModalConfig =
      externalSiteModalConfig as ExternalSiteModalConfig;
  }

  ngAfterViewInit() {
    if (!this.linkConfig.button && this.linkConfig.removeLinkStyles)
      this.removeLinkStyles();
  }

  removeLinkStyles() {
    if (!this.linkConfig.externalLink && !this.linkConfig.route) {
      this.renderer.addClass(this.content.nativeElement, 'link--NoUrl');
    }
    if (!this.linkConfig.externalLink) {
      const els: any[] = this.content.nativeElement.children;

      for (const element of els) {
        this.renderer.removeClass(element, 't-link');
        this.renderer.setStyle(element, 'cursor', 'default');
      }
    }
  }

  handleLinkClick(event, linkConfig: LinkConfig) {
    this.linkClicked.emit(linkConfig);

    if (linkConfig.goBack) {
      this.location.back();
    } else if (linkConfig.externalLink) {
      this.resolveExternalLink(linkConfig);
    } else if (linkConfig.route) {
      const linkRoute = linkConfig.route;

      if (linkRoute.includes('email:') || linkRoute.includes('tel:')) {
        this.window.nativeWindow.location.href = linkRoute;
      } else {
        const parsedUrl = this.router.parseUrl(linkConfig.route);

        this.router.navigateByUrl(parsedUrl);
      }
    }
    stopEvent(event);
    this.linkService.setLinkClickListener(linkConfig);
    this.closeModal();
  }

  openModal(event) {
    if (!this.externalSiteModalConfig) {
      this.logger.warn('External Site Modal Config does not exist');
    }
    this.isModalOpen = true;
    this.openExternalLinkModal.emit(event);
  }

  closeModal() {
    this.isModalOpen = false;
  }

  resolveExternalLink(linkConfig: LinkConfig) {
    switch (linkConfig.target) {
      case NavigationLinkTargets.new_window:
        const window = this.window.nativeWindow;
        const dimensions = `width=${window.innerWidth},height=${window.innerHeight}`;

        window.open(linkConfig.externalLink, linkConfig.label, dimensions);
        break;
      case NavigationLinkTargets.new_tab:
        Object.assign(document.createElement('a'), {
          target: '_blank',
          href: linkConfig.externalLink,
        }).click();
        break;
      default:
        this.window.nativeWindow.location.href = linkConfig.externalLink;
        break;
    }
  }

  getLinkEvent(): LinkConfig & Pick<EventInfo, 'eventName'> {
    if (this.linkConfig?.externalLink) {
      return {
        ...this.linkConfig,
        eventName: null,
      };
    } else return null;
  }
}
