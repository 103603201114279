import { Injectable } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { WindowService } from '@zipari/web-services';
import { ConfigService } from '../../../shared/services';

@Injectable({
  providedIn: 'root',
})
export class DocumentCenterService {
  public tabsConfig: any;
  public activeTabObj: any;

  constructor(
    private configService: ConfigService,
    private window: WindowService,
  ) {}

  public getTargetRoute(route: ActivatedRoute): UrlSegment | undefined {
    const minimumSegmentLength = 3;
    const indexOfRouteSegment = 2;
    const segments = route.snapshot?.['_routerState']?.url.split('/');

    segments?.shift(); // removes empty segment to match previous urlSegment array

    if (segments?.length >= minimumSegmentLength) {
      return segments[indexOfRouteSegment];
    } else {
      this.navigateToDashboard();
    }
  }

  private navigateToDashboard() {
    const hostUrl = this.window.nativeWindow.location.origin;
    const portalUrlSegment = this.configService.appRoute || 'provider-portal';
    const newUrl = `${hostUrl}/${portalUrlSegment}/dashboard`;

    this.window.nativeWindow.location.assign(newUrl);
  }
}
