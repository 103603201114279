import { Component, Input } from '@angular/core';
import { ColumnConfig } from '../../../models/column.model';

@Component({
  selector: 'table-summary-cell',
  templateUrl: './summary-cell.component.html',
  styleUrls: ['./summary-cell.component.scss'],
})
export class SummaryCellComponent {
  @Input() index: number;
  @Input() column: ColumnConfig;
  @Input()
  set data(data) {
    this._cellValue = this.index === 0 ? 'Total' : data;
  }
  _cellValue: any = '';
}
