import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { ControlCheckboxModule } from '../form-control/checkbox/checkbox.module';
import { ButtonModule } from '../button/button.module';
import { CheckboxListComponent } from './checkbox-list.component';
import { ExpandingArticleModule } from '../expanding-article/expanding-article.module';

@NgModule({
  declarations: [CheckboxListComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatCheckboxModule,
    ControlCheckboxModule,
    ButtonModule,
    ExpandingArticleModule,
  ],
  exports: [CheckboxListComponent],
})
export class CheckboxListModule {}
