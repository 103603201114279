import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SliderConfiguration } from '@zipari/shared-ds-util-form';

@Component({
  selector: 'control-slider',
  templateUrl: './slider.component.html',
  styleUrl: './slider.component.scss',
})
export class SliderComponent implements OnInit {
  @Input() config: SliderConfiguration;
  @Input() control: UntypedFormControl;
  @Output() valueChange = new EventEmitter<number>();
  @Output() rangeValueChange = new EventEmitter<{
    start: number;
    end: number;
  }>();

  public value: number;
  public startValue: number;
  public endValue: number;

  ngOnInit(): void {
    this.value = this.config.min as number;
    this.startValue = this.config.min as number;
    this.endValue = this.config.max as number;
  }

  formatLabel = (value: number): string =>
    this.config?.prefix ? `${this.config.prefix}${value}` : `${value}`;

  onValueChange(value: number) {
    this.valueChange.emit(value);
  }

  onStartValueChange(value: number) {
    this.rangeValueChange.emit({ start: value, end: this.endValue });
  }

  onEndValueChange(value: number) {
    this.rangeValueChange.emit({ start: this.startValue, end: value });
  }
}
