<div
  class="table__header"
  [ngClass]="{
    'multiple-select': ztService.zipTableOptions.selection.multiple,
  }"
>
  <div
    class="table__row__expand"
    *ngIf="((detailView && multiExpandedAllowed) || grouping) && dataExists"
  >
    <icon
      [name]="allExpanded ? 'unfold_less' : 'unfold_more'"
      [config]="{ type: 'pointer' }"
      (click)="expandAllRows.emit()"
    >
    </icon>
  </div>

  <ng-container *ngIf="ztService.zipTableOptions.selection.multiple">
    <input
      class="form-control form-control--checkbox table--checkbox header--checkbox"
      type="checkbox"
      [checked]="ztService.allRowsAreSelected"
      (change)="markAllRowsAsSelected($event)"
    />
  </ng-container>

  <ng-container
    *ngIf="
      ztService.zipTableOptions.selection.editHeader &&
        ztService.selectedRow.length > 0;
      else normalHeaderColumns
    "
  >
    <div class="table__cell table__cell--header u-relative" role="columnheader">
      <p class="t-data t-bold">
        {{ ztService.selectedRow.length }} rows selected
      </p>

      <div class="button__group">
        <zip-button
          *ngIf="checkHeaderButton(HeaderButtonType.EDIT)"
          (zipButtonClicked)="editClicked.emit($event)"
          [level]="
            ztService.zipTableOptions?.selection?.editHeaderConfig?.edit
              ?.level || 'low'
          "
          icon="edit"
          content="Edit"
        >
        </zip-button>
        <zip-button
          *ngIf="checkHeaderButton(HeaderButtonType.DELETE)"
          (zipButtonClicked)="deleteClicked.emit($event)"
          [level]="
            ztService.zipTableOptions?.selection?.editHeaderConfig?.delete
              ?.level || 'low'
          "
          icon="trash"
          [content]="checkDeleteText('Delete')"
        >
        </zip-button>
        <div
          class="headerButton"
          *ngFor="
            let headerButton of ztService.zipTableOptions.selection
              .editHeaderConfig?.headerButtons
          "
        >
          <zip-button
            *ngIf="!headerButton.isHidden"
            (zipButtonClicked)="headerButtonClicked.emit(ztService.selectedRow)"
            [level]="
              ztService.zipTableOptions?.selection?.editHeaderConfig?.headerBtns
                ?.level || 'low'
            "
            [icon]="headerButton.icon"
            [content]="headerButton.content"
            [ngClass]="headerButton.customCSSClass"
          >
          </zip-button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #normalHeaderColumns>
    <ng-container *ngFor="let column of columns; let i = index">
      <table-header-cell
        *ngIf="!column.isHidden"
        [ngClass]="getCSSClasses(column)"
        [attr.title]="column.tooltip"
        class="table__cell table__cell--header u-relative"
        [sorts]="options?.sorts"
        [column]="column"
        [dataExists]="dataExists"
        (headerButtonClicked)="headerButtonClicked.emit($event)"
        (onSortByField)="fireSortEvent($event)"
        [stackedIcons]="options?.sorting?.stackedIcons"
        role="columnheader"
      >
      </table-header-cell>
    </ng-container>
  </ng-template>
</div>
