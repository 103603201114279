import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  ModelAttributeConfig,
  ModelAttributeDirections,
} from '@zipari/shared-ds-util-configuration';
import { headingTags } from './model-attribute-header.constants';

@Component({
  selector: 'zipari-model-attribute-header',
  standalone: false,
  templateUrl: './model-attribute-header.component.html',
  styleUrl: './model-attribute-header.component.scss',
})
export class ModelAttributeHeaderComponent implements OnInit {
  @Input() config: ModelAttributeConfig;
  @Input() label: string;
  @Input() direction: ModelAttributeDirections;
  @Input() value: unknown;
  @ViewChild('headerContainer', { static: true }) public headerContainer;
  public element: string;

  public ngOnInit(): void {
    const { headingLevel, id, prop, label, ariaLabel } = this.config;
    const headingTagLevel = headingLevel || 5;
    const headingLevelIndex = headingTagLevel - 1; // 0 based indexing
    const dynamicClass = ['column', 'row_equal', 'row'].includes(this.direction)
      ? 't-bold'
      : '';
    const headerElement = document.createElement(
      headingTags[headingLevelIndex],
    );

    headerElement.classList.add('ModelAttributeHeader__LabelText');
    dynamicClass && headerElement.classList.add(dynamicClass);
    headerElement.setAttribute('for', `${label}:${this.value}`);
    headerElement.setAttribute('aria-label', ariaLabel || `Label for ${label}`);
    headerElement.setAttribute(
      'data-test-id',
      `AttributeRowFor${id || prop || label}`,
    );
    headerElement.innerHTML = this.label;

    this.headerContainer.nativeElement.prepend(headerElement);
  }
}
