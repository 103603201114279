<section class="CheckboxList">
  <span *ngIf="showSelectAll && config?.controls">
    <span *ngFor="let checkbox of config.controls">
      <mat-checkbox
        #selectCheckbox
        [checked]="areAllOptionChecked"
        [indeterminate]="getIndeterminateStatus()"
        (change)="checkAllOptions()"
        (indeterminateChange)="checkForIndeterminateStatus()"
      >
        {{ checkbox.label }}
      </mat-checkbox>
    </span>
  </span>
  <span>
    <ul
      class="CheckboxList__CheckboxOptions"
      [ngClass]="{ CheckboxList__CheckboxListLayout: layout === 'horizontal' }"
    >
      @for (option of checkboxOptions; track option) {
        <li
          [ngClass]="{
            CheckboxList__CheckboxListOptionLayout: layout === 'horizontal',
          }"
        >
          <mat-checkbox
            class="CheckboxList_CheckboxOptions"
            [checked]="option?.defaultChecked && !option?.disabled"
            [disabled]="option?.disabled || null"
            (change)="setOptionCheckedStatus($event, option)"
          >
            {{ option.label }}
          </mat-checkbox>
          @if (option?.description) {
            <div class="CheckboxList__CheckboxOptionsDescription">
              <expanding-article
                class="CheckboxList_CheckboxOptions"
                [text]="option.description"
                [config]="expandingTextConfig"
              >
              </expanding-article>
            </div>
          }
        </li>
      }
    </ul>
  </span>
</section>
