import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  IconConfig,
  IconConfiguration,
  IconFormats,
  IconSets,
  IconSizes,
  IconTransforms,
  IconTypes,
} from './icon.model';
import { checkInputsForText } from '../../design-system.helper';

@Component({
  selector: 'icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit, OnChanges {
  @Input() config: IconConfig;
  @Input() name: string;
  @Input() isDisabled: boolean;
  @Input() label: string;

  sets = IconSets;
  sizes = IconSizes;
  types = IconTypes;
  transforms = IconTransforms;
  formats = IconFormats;

  icon: IconConfiguration;

  get iconModifiers(): object {
    /* eslint-disable  @typescript-eslint/naming-convention */ // CSS BEM classes are invalid JS object keys
    return {
      'Icon--small': this.icon.size === this.sizes.small,
      'Icon--medium': this.icon.size === this.sizes.medium,
      'Icon--large': this.icon.size === this.sizes.large,
      'Icon--pointer':
        this.icon.type === this.types.pointer ||
        this.icon.type === this.types.link,
      'Icon--move': this.icon.type === this.types.move,
      'Icon--rotate90': this.icon.transform === this.transforms.rotate90,
      'Icon--rotate180': this.icon.transform === this.transforms.rotate180,
      'Icon--flip': this.icon.transform === this.transforms.flip,
      'Icon--disabled': this.isDisabled,
    };
    /* eslint-enable  @typescript-eslint/naming-convention */
  }

  ngOnInit() {
    const [config] = checkInputsForText([this.config]);

    this.config = config;
    this.icon = new IconConfiguration(this.config);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.config?.firstChange) {
      const icon = {
        ...this.config,
        fill: changes?.config?.currentValue?.fill || this.config?.fill,
        transform:
          changes?.config?.currentValue?.transform || this.config?.transform,
      };

      this.icon = new IconConfiguration(icon);
    }
  }
}
