import { Component, Input } from '@angular/core';
import { TableConfig } from '../../../models/table.model';
import { ColumnConfig } from './../../../models/column.model';
import { FormattingService } from '../../../../../shared/services/formatting.service';

@Component({
  selector: 'table-summary-row',
  templateUrl: './summary-row.component.html',
  styleUrls: ['./summary-row.component.scss'],
})
export class SummaryRowComponent {
  @Input() columns: ColumnConfig[];
  @Input() data: any;
  @Input() options: TableConfig;

  getTotalValue(items, column) {
    const unformattedValue = items.reduce((accumulator, currentItem) => {
      const currentValue = currentItem[column?.prop];

      if (typeof currentValue === 'number' || currentValue === undefined) {
        return accumulator + (currentValue || 0);
      }

      return '--';
    }, 0);

    return typeof unformattedValue === 'number'
      ? this.formattingService.restructureValueBasedOnFormat(
          unformattedValue,
          column,
        )
      : '--';
  }
  constructor(public formattingService: FormattingService) {}
}
