export enum formatTypes {
  ACTIONS = 'ACTIONS',
  ADDRESS = 'ADDRESS',
  ADDRESSES = 'ADDRESSES',
  APP_DISPLAY_NAME = 'APP_DISPLAY_NAME',
  BADGE = 'BADGE',
  BANK_OR_CHECK = 'BANK_OR_CHECK',
  BOOLEAN_STRING_MAP = 'BOOLEAN_STRING_MAP',
  CREDIT_CARD = 'CREDIT_CARD',
  CURRENCY = 'CURRENCY',
  DATE = 'DATE',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  TIME = 'TIME',
  DATE_RANGE = 'DATE_RANGE',
  EMAIL = 'EMAIL',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
  FROM_OBJECT_LIST = 'FROM_OBJECT_LIST',
  FULL_NAME = 'FULL_NAME',
  ID = 'ID',
  INBOX_DATE = 'INBOX_DATE',
  LICENSE_ACTIVE = 'LICENSE_ACTIVE',
  LIST = 'LIST',
  LINK = 'LINK',
  MASKED = 'MASKED',
  MEMBER_TYPE = 'MEMBER_TYPE',
  NUM_DAYS_FROM_DATE = 'NUM_DAYS_FROM_DATE',
  PAYMENT_FREQUENCY = 'PAYMENT_FREQUENCY',
  PERCENT = 'PERCENT',
  PHONE = 'PHONE',
  PHONE_AND_TYPE = 'PHONE_AND_TYPE',
  SNAKE_CASE_TO_TITLE = 'SNAKE_CASE_TO_TITLE',
  SSN = 'SSN',
  STATIC_GRID = 'STATIC_GRID',
  STATUS = 'STATUS',
  SUCCESS_ERROR = 'SUCCESS_ERROR',
  TAXID = 'TAXID',
  VALUE_MAP = 'VALUE_MAP',
  YES_NO = 'YES_NO',
  YES_NO_NA = 'YES_NO_NA',
  BOOLEAN_ICON = 'BOOLEAN_ICON',
  DATE_OF_BIRTH_AND_AGE = 'DATE_OF_BIRTH_AND_AGE',
  SNAKE_CASE_TO_TITLE_CAPITALIZATION = 'SNAKE_CASE_TO_TITLE_CAPITALIZATION',
  EXPANDING_LIST = 'EXPANDING_LIST',
}

export const DEFAULT_UNMASKED_CHAR = 4;

export const appDisplayNames = {
  zshop: 'Shopping Portal',
  'employer-portal': 'Employer Portal',
  'member-portal': 'Member Portal',
  'provider-portal': 'provider Portal',
  'broker-portal': 'Broker Portal',
};

export const defaultCurrencyOptions = {
  locale: 'en-US',
  digitsInfo: '1.2-2',
  currencyCode: 'USD',
  display: 'symbol',
};

export const defaultDateOptions = {
  format: 'MM/dd/yyyy',
};
