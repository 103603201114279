<section class="layout--list" *ngIf="config">
  <section class="layout--list__header">
    <header>
      <h4
        role="heading"
        aria-level="1"
        class="t-bold"
        [innerHTML]="config.header.label"
      ></h4>
    </header>
  </section>
  <section class="layout--list__content">
    <tabs
      pageTabs="true"
      [tabLabels]="tabLabels"
      (tabSelected)="tabSelected($event)"
      [selectedIndex]="activeTab"
    >
    </tabs>
    <div class="container Tab_View">
      <router-outlet></router-outlet>
    </div>
  </section>
</section>
