<div
  class="ModelAttributeHeader__Label u-flex"
  [ngClass]="{ 'u-fillRemaining': direction === 'row_equal' }"
  #headerContainer
>
  <!-- Heading will be added above this comment -->
  <tooltip
    class="ModelAttributeHeader__Tooltip"
    [config]="config.tooltip"
    data-test-id="modelAttributeTooltip"
  ></tooltip>
</div>
