import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SidePanelState } from './side-panel.reducers';

export const selectSidePanelState =
  createFeatureSelector<SidePanelState>('side_panel');

export const selectClaimDetailState = createFeatureSelector('claim-detail');

export const selectSidePanelConfig = createSelector(
  selectSidePanelState,
  (state: SidePanelState) => state.config,
);

export const selectActiveClaim = createSelector(
  selectClaimDetailState,
  (state: any) => state?.data?.response,
);
