import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  BannerTypes,
  MessageBannerConfig,
} from '@zipari/shared-ds-util-messages';
import { checkInputsForText } from '../../design-system.helper';

@Component({
  selector: 'message-banner',
  templateUrl: './message-banner.component.html',
  styleUrls: ['./message-banner.component.scss'],
})
export class MessageBannerComponent implements OnInit {
  @Input() config: MessageBannerConfig;
  @Input() useCustomContent: boolean;
  @Input() useCustomContentColumn: boolean;
  @Output() actionClicked = new EventEmitter();
  @Output() buttonClicked = new EventEmitter();

  bannerTypes = BannerTypes;

  get message(): SafeHtml {
    return this.config
      ? this.sanitizer.sanitize(SecurityContext.HTML, this.config.message)
      : null;
  }

  get iconFill(): string {
    switch (this.config.type) {
      case BannerTypes.error:
        return 'error';
      case BannerTypes.warning:
        return 'warn';
      case BannerTypes.success:
        return 'success';
      case BannerTypes.information:
        return 'primary';
      case BannerTypes.info_secondary:
        return 'secondary--5';
      default:
        return 'icon-color';
    }
  }

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    const [config] = checkInputsForText([this.config]);

    this.config = config;
  }
}
