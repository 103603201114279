import { formatTypes } from '@zipari/shared-ds-util-format';
import { ObjectStatuses } from '@zipari/shared-ds-util-status';
import { Tooltip } from '@zipari/shared-ds-util-tooltip';

export enum ModelAttributeDirections {
  row_equal = 'row_equal',
  row = 'row',
  column = 'column',
}

export class LinkConfiguration {
  noLink?: boolean;
  noBold?: boolean;
}

export class ModelAttributeConfig {
  label!: string;
  id?: string;
  ariaLabel?: string;
  value?: any;
  text?: string;
  prop?: string;
  route?: string;
  isExternalRoute?: boolean;
  noValueMessage?: string = '';
  format?: formatTypes;
  subformat?: formatTypes;
  icon?: string;
  context?: string;
  status_object?: string;
  statuses?: ObjectStatuses;
  typeProp?: string;
  statusProp?: string;
  formatOptions?: LinkConfiguration;
  hideIfZeroOrNull?: boolean = false;
  tooltip?: Tooltip;
  target?: '_blank' | '_self' | '_parent' | '_top';
  allowZeroValue?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
  /**
   * Used when format type is BOOLEAN_STRING_MAP
   */
  truthyValue?: string;
  falsyValue?: string;
  componentType?: ComponentTypes;
  useConfigAddressLabel?: boolean;
  truncateLong?: boolean;

  /* istanbul ignore next */
  constructor(options = {}) {
    Object.assign(this, options);
  }
}

export enum ComponentTypes {
  badge = 'Badge',
}

export interface AddressLabels {
  label: string;
  prop: string;
  value: string;
}
