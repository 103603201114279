import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LinkListItem } from './link-list.model';
import { checkInputsForText } from '../../design-system.helper';

@Component({
  selector: 'link-list',
  templateUrl: './link-list.component.html',
  styleUrls: ['./link-list.component.scss'],
})
export class LinkListComponent implements OnInit {
  @Input() dataList: LinkListItem[];

  @Output() clicked = new EventEmitter();

  @Output() customMethod: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    const [dataList] = checkInputsForText([this.dataList]);

    this.dataList = dataList;
  }

  linkClicked(data: LinkListItem, context?: string) {
    this.clicked.emit(data);

    if (this.customMethod.observers.length) {
      this.customMethod.emit({
        event: data,
        context,
      });
    }
  }
}
