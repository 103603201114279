<div
  class="TableHeader__Action--Desktop"
  data-test-id="tableHeaderActionDesktop"
  *ngIf="ztService?.showExport || ztService?.zipTableOptions?.button"
>
  <zip-button
    *ngIf="ztService?.showButton"
    class="TableHeader__ActionButton--Desktop"
    (zipButtonClicked)="actionButtonClicked.emit($event)"
    [disabled]="ztService.loading"
    [config]="ztService?.zipTableOptions?.button"
  >
  </zip-button>

  <ng-container *ngIf="ztService?.showExport">
    <ng-container *ngIf="showAsyncExport(); else csvDownloadLink">
      <zip-button
        class="TableHeader__ActionMenu--Desktop"
        [disabled]="ztService.loading"
        (zipButtonClicked)="ztService.getAsyncExport()"
        [config]="ztService?.zipTableOptions?.exportConfig"
      >
      </zip-button>
    </ng-container>

    <ng-template #csvDownloadLink>
      <zip-button
        [disabled]="ztService.loading"
        class="TableHeader__ActionMenuMask--Desktop"
        (zipButtonClicked)="ztService.getSynchronousExport()"
        [content]="ztService?.zipTableOptions?.exportConfig.text || null"
        level="medium"
        [icon]="ztService?.zipTableOptions?.exportConfig?.icon"
      >
      </zip-button>
    </ng-template>
  </ng-container>
</div>
<div
  class="TableHeader__Action--Mobile"
  data-test-id="tableHeaderActionMobile"
  *ngIf="ztService?.showExport || ztService?.zipTableOptions?.button"
>
  <section
    class="TableHeader__ActionMenuMask--Mobile"
    data-test-id="tableHeaderActionMenuMaskMobile"
    *ngIf="showMobileActionMenu"
    (click)="showMobileActionMenu = false"
    @fade
  ></section>

  <section
    class="TableHeader__ActionMenu--Mobile"
    data-test-id="tableHeaderActionMenuMobile"
    *ngIf="showMobileActionMenu"
    @fade
  >
    <zip-button
      *ngIf="ztService?.showButton"
      class="TableHeader__ActionMenuItem--Mobile"
      data-test-id="tableHeaderActionMenuItemMobile"
      [config]="{
        level: 'text',
        content: ztService?.zipTableOptions?.button?.content,
      }"
      (zipButtonClicked)="
        actionButtonClicked.emit($event); showMobileActionMenu = false
      "
    >
    </zip-button>

    <ng-container *ngIf="ztService?.showExport">
      <zip-button
        *ngIf="showAsyncExport(); else csvDownloadLink"
        class="TableHeader__ActionMenuItem--Mobile"
        data-test-id="tableHeaderActionMenuItemMobile"
        [config]="{ level: 'text', content: 'Export' }"
        (zipButtonClicked)="
          ztService.getAsyncExport(); showMobileActionMenu = false
        "
      >
      </zip-button>

      <ng-template #csvDownloadLink>
        <zip-button
          class="TableHeader__ActionMenuItem--Mobile"
          data-test-id="tableHeaderActionMenuItemMobile"
          [config]="{ level: 'text', content: 'Export CSV' }"
          (zipButtonClicked)="
            ztService.getSynchronousExport(); showMobileActionMenu = false
          "
        >
        </zip-button>
      </ng-template>
    </ng-container>
  </section>

  <zip-button
    *ngIf="!!this.ztService.showButton || !!this.ztService.showExport"
    class="TableHeader__ActionButton--Mobile"
    data-test-id="tableHeaderActionButtonMobile"
    [config]="{ icon: 'more' }"
    (zipButtonClicked)="showMobileActionMenu = true"
  >
  </zip-button>
</div>
