@if (
  (!isHidden && !config.hideIfZeroOrNull) ||
  (config.hideIfZeroOrNull && valueIsGreaterThanZero())
) {
  <div class="model-attribute">
    <div class="u-flex">
      <icon
        class="model-attribute__icon"
        *ngIf="config.icon"
        [name]="config.icon"
      >
      </icon>

      <div
        tabindex="0"
        class="model-attribute__wrapper"
        [ngClass]="{
          'model-attribute__wrapper--row':
            direction === 'row' || direction === 'row_equal',
        }"
      >
        @if (
          !isLabelExcludedForThisFormat && !isLabelExcludedForThisComponent
        ) {
          <zipari-model-attribute-header
            [config]="config"
            [label]="label"
            [direction]="direction"
            [value]="value"
          ></zipari-model-attribute-header>
        }

        @if (!config.componentType) {
          <div class="model-attribute__value u-fillRemaining">
            <ng-container [ngSwitch]="displayType">
              <ng-container *ngSwitchCase="displayTypes.ADDRESSES">
                <div class="u-wrap model-attribute__multi-address">
                  @for (
                    addressValue of value;
                    track addressValue;
                    let idx = $index
                  ) {
                    <div class="multi-address__wrapper">
                      @if (
                        addressValue &&
                          addressValue.length > 1 &&
                          getAddressLabel(context, idx);
                        as label
                      ) {
                        @if (config.typeProp && config.useConfigAddressLabel) {
                          <h3
                            class="model-attribute__label__address model-attribute__Label"
                            [attr.for]="'address-' + idx"
                          >
                            <span [innerHTML]="label"></span>
                            <tooltip
                              class="model-attribute__tooltip"
                              [config]="config.tooltip"
                              data-test-id="modelAttributeTooltipForAddress"
                            ></tooltip>
                          </h3>
                        }
                      }
                      @if (addressValue) {
                        <p
                          [attr.id]="'address-' + idx"
                          [innerHTML]="addressValue"
                        ></p>
                      }
                    </div>
                  }
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="displayTypes.BOOLEAN_STRING_MAP">
                <p
                  [attr.id]="label + ':' + value"
                  [innerHTML]="!!value ? config.truthyValue : config.falsyValue"
                ></p>
              </ng-container>

              <ng-container *ngSwitchCase="displayTypes.EMAIL">
                <a
                  [attr.id]="label + ':' + value"
                  [ngClass]="{
                    't-link': !config?.formatOptions?.noLink,
                    't-regular': config?.formatOptions?.noBold,
                  }"
                  [href]="'mailto: ' + value"
                  [innerHTML]="value"
                  (click)="onLinkClicked()"
                  role="link"
                  [attr.aria-label]="'link for: ' + label"
                  [attr.data-test-id]="'modelAttributeFor' + label"
                >
                </a>
              </ng-container>

              <ng-container *ngSwitchCase="displayTypes.EXTERNAL_LINK">
                <a
                  class="t-link"
                  [ngClass]="{ 't-bold': !label }"
                  [attr.id]="label + ':' + value"
                  [attr.target]="config.target ? config.target : '_blank'"
                  [href]="route || value"
                  [innerHTML]="text || value"
                  (click)="onLinkClicked()"
                  role="link"
                  [attr.aria-label]="'External link for: ' + label"
                  [attr.data-test-id]="
                    'modelAttributeForExternalLink' + (text || value)
                  "
                >
                </a>
              </ng-container>

              <ng-container *ngSwitchCase="displayTypes.LINK">
                <a
                  tabindex="0"
                  class="t-link"
                  [attr.id]="label + ':' + value"
                  [ngClass]="{
                    't-bold': !label || direction === 'row_equal',
                  }"
                  (click)="onLinkClicked()"
                  [innerHTML]="text || value"
                  role="link"
                  [attr.aria-label]="'link for: ' + label"
                  [attr.data-test-id]="
                    'modelAttributeForLink' + (text || value)
                  "
                >
                </a>
              </ng-container>

              <ng-container *ngSwitchCase="displayTypes.LIST">
                <ul
                  tabindex="0"
                  class="model-attribute__list"
                  *ngIf="valueIsList; else noListValues"
                >
                  <li *ngFor="let val of value" [attr.id]="label + ':' + value">
                    <ng-container [ngSwitch]="displaySubtype">
                      <ng-container *ngSwitchCase="displayTypes.PHONE">
                        <a
                          class="t-link"
                          [attr.href]="'tel: ' + val"
                          [innerHTML]="val"
                          (click)="onLinkClicked()"
                          role="link"
                          [attr.aria-label]="'link for: ' + label"
                          [attr.data-test-id]="
                            'modelAttributeForPhoneTypeListItem' + val
                          "
                        ></a>
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                        <p>{{ val }}</p>
                      </ng-container>
                    </ng-container>
                  </li>
                </ul>
                <ng-template #noListValues>
                  <ul tabindex="0" class="model-attribute__list">
                    <li>
                      <p tabindex="0">
                        {{ config?.noValueMessage || 'n/a' }}
                      </p>
                    </li>
                  </ul>
                </ng-template>
              </ng-container>

              <ng-container *ngSwitchCase="displayTypes.PHONE_AND_TYPE">
                @if (!value || value === config.noValueMessage) {
                  <p [attr.id]="label + ':' + value" [innerHTML]="value"></p>
                } @else {
                  <ul
                    class="model-attribute__list"
                    *ngFor="let phone_number_and_type of value"
                    [attr.id]="label + ':' + value"
                  >
                    <li>
                      <a
                        tabindex="0"
                        [ngClass]="{
                          't-link': !config?.formatOptions?.noLink,
                          't-regular': config?.formatOptions?.noBold,
                        }"
                        [attr.href]="
                          config?.formatOptions?.noLink ? null : 'tel: ' + value
                        "
                        (click)="onLinkClicked()"
                        role="link"
                        [attr.aria-label]="'link for: ' + label"
                        [attr.data-test-id]="
                          'modelAttributeListItemForList' +
                          phone_number_and_type
                        "
                        [innerHTML]="phone_number_and_type"
                      >
                      </a>
                    </li>
                  </ul>
                }
              </ng-container>

              <ng-container *ngSwitchCase="displayTypes.PHONE">
                <a
                  tabindex="0"
                  [ngClass]="{
                    't-link': !config?.formatOptions?.noLink,
                    't-regular': config?.formatOptions?.noBold,
                  }"
                  [attr.id]="label + ':' + value"
                  [attr.href]="
                    config?.formatOptions?.noLink ? null : 'tel: ' + value
                  "
                  (click)="onLinkClicked()"
                  [innerHTML]="value"
                  role="link"
                  [attr.aria-label]="'link for: ' + label"
                  [attr.data-test-id]="'modelAttributeFor' + label"
                ></a>
              </ng-container>

              <ng-container *ngSwitchCase="displayTypes.STATUS">
                <status
                  [attr.id]="label + ':' + value"
                  [config]="{
                    label: value,
                    statuses: config.statuses,
                  }"
                >
                </status>
              </ng-container>

              <ng-container *ngSwitchCase="displayTypes.VALUE_MAP">
                <p [attr.id]="label + ':' + value" [innerHTML]="value"></p>
              </ng-container>

              <ng-container *ngSwitchDefault>
                <p
                  [attr.id]="label + ':' + value"
                  [innerHTML]="'&#8203;' + value"
                ></p>
              </ng-container>
            </ng-container>
          </div>
        } @else {
          @if (config.componentType === componentTypes.badge) {
            <badge [config]="badgeConfig" [context]="context"></badge>
          }
        }
      </div>
    </div>
  </div>
}
