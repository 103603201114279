import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormGroupModule } from '../../chords/form-group/form-group.module';
import { IconModule } from '../../notes/icon/icon.module';
import { StatusModule } from '../../notes/status/status.module';
import { FormattingService } from '../../shared/services/formatting.service';
import { BadgeModule } from '../badge/badge.module';
import { TooltipModule } from '../tooltip/tooltip.module';

import { ModelAttributeComponent } from './model-attribute.component';
import { ModelAttributeHeaderComponent } from './model-attribute-header/model-attribute-header.component';

@NgModule({
  declarations: [ModelAttributeComponent, ModelAttributeHeaderComponent],
  imports: [
    CommonModule,
    FormGroupModule,
    IconModule,
    BadgeModule,
    StatusModule,
    TooltipModule,
  ],
  providers: [CurrencyPipe, FormattingService, DatePipe],
  exports: [ModelAttributeComponent],
})
export class ModelAttributeModule {}
