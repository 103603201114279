import { Component, Input, OnInit } from '@angular/core';

import { BannerConfiguration } from '@zipari/shared-ds-util-messages';
import { checkInputsForText } from '../../design-system.helper';

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  @Input() config: BannerConfiguration;
  @Input() reverse = false;

  ngOnInit(): void {
    const [config, reverse] = checkInputsForText([this.config, this.reverse]);

    this.config = config as BannerConfiguration;
    this.reverse = reverse as boolean;
  }
}
