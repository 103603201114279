import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReadMoreModule } from '../../shared/modules/read-more/read-more.module';
import { FormattingService } from '../../shared/services/formatting.service';
import { IconModule } from '../icon/icon.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { FileUploaderModule } from './../../chords/file-upload/file-uploader.module';
import { ControlCheckboxModule } from './checkbox/checkbox.module';
import { ControlDateRangeModule } from './date-range/date-range.module';
import { ControlDropdownModule } from './dropdown/dropdown.module';
import { FormControlComponent } from './form-control.component';
import { ControlPasswordModule } from './password/control-password.module';
import { ControlRadioModule } from './radio/radio.module';
import { SingleCheckboxModule } from './single-checkbox/single-checkbox.module';
import { StatesModule } from './states/states.module';
import { ControlSwitchModule } from './switch/switch.module';
import { ControlTextModule } from './text/text.module';
import { ControlTextAreaModule } from './textarea/textarea.module';
import { ControlTelModule } from './tel/tel.module';
import { ControlTimeRangeModule } from './time-range/time-range.module';
import { ControlTimeModule } from './time/time.module';
import { ControlToggleModule } from './toggle/toggle.module';
import { ControlTypeaheadModule } from './typeahead/control-typeahead.module';
import { DatePickerModule } from './date-picker/date-picker.module';
import { SliderModule } from './slider/slider.module';
import * as Pipes from './form-control.pipes';

@NgModule({
  declarations: [
    FormControlComponent,
    Pipes.ShowRequiredAsterisk,
    Pipes.ShowOptionalLabel,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IconModule,
    ControlDropdownModule,
    ControlRadioModule,
    ControlCheckboxModule,
    ControlSwitchModule,
    ControlTextModule,
    ControlToggleModule,
    ControlTextAreaModule,
    ControlTimeModule,
    ReactiveFormsModule,
    ControlTypeaheadModule,
    ControlPasswordModule,
    SingleCheckboxModule,
    SliderModule,
    ReadMoreModule,
    ControlDateRangeModule,
    ControlTimeRangeModule,
    StatesModule,
    FileUploaderModule,
    TooltipModule,
    ControlTelModule,
    DatePickerModule,
  ],
  providers: [FormattingService, CurrencyPipe, DatePipe],
  exports: [FormControlComponent],
})
export class FormControlModule {}
