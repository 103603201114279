@if (config?.showSummaryRow) {
  <section class="datagrid__summary datagrid__row__wrapper" role="row">
    <div class="datagrid__row">
      @for (column of config?.columns; track $index) {
        <div
          class="datagrid__cell"
          [ngClass]="{
            'datagrid__cell--ellipsis': column.width,
            'datagrid__cell--alignRight': column.isRightAligned,
            'datagrid__cell--alignLeft': column.isLeftAligned,
            'datagrid__cell--alignCenter':
              column.isCenterAligned ||
              (!column.isLeftAligned && !column.isRightAligned),
          }"
          [ngStyle]="{
            width: getColumnWidth(column),
            'min-width': getColumnWidth(column),
            'max-width': getColumnWidth(column),
          }"
          role="cell"
        >
          <p
            class="t-bold"
            [innerHTML]="$index === 0 ? 'Total' : getTotalValue(rows, column)"
          ></p>
        </div>
      }
    </div>
  </section>
}
