<div
  class="message-banner"
  [ngClass]="{
    'message-banner--info': config?.type === bannerTypes.information,
    'message-banner--error': config?.type === bannerTypes.error,
    'message-banner--warn': config?.type === bannerTypes.warning,
    'message-banner--success': config?.type === bannerTypes.success,
    'message-banner--info-secondary':
      config?.type === bannerTypes.info_secondary,
  }"
  *ngIf="!config?.hideBanner"
>
  <div class="message-banner__main-content">
    <icon
      *ngIf="config?.icon"
      [name]="config.icon"
      [config]="{ fill: iconFill }"
    >
    </icon>

    <div class="message-banner__text">
      <p *ngIf="config?.messageHeading" class="message-banner__title">
        {{ config.messageHeading }}
      </p>

      <p class="t-html message-banner__text-section" [innerHTML]="message"></p>

      <zip-button
        class="message-banner__button"
        *ngIf="config?.actionButton"
        [config]="config?.actionButton"
        (zipButtonClicked)="buttonClicked.emit($event)"
      >
      </zip-button>
    </div>

    @if (useCustomContent) {
      <div class="u-flex u-alignCenter u-justifyEnd">
        <ng-content select="[useCustomContent]"></ng-content>
      </div>
    } @else {
      <button
        type="button"
        (click)="actionClicked.emit($event)"
        class="message-banner__action-button u-flex u-alignCenter u-justifyEnd"
        [attr.aria-label]="config?.actionText || 'Close'"
      >
        <icon
          *ngIf="config?.actionIcon"
          [name]="config.actionIcon"
          [config]="{
            type: 'pointer',
            size: 'small',
          }"
          [attr.aria-label]="config?.actionIconAltText"
        >
        </icon>

        <p
          *ngIf="config?.actionText"
          class="message-banner__action"
          [ngClass]="{
            't-error': config?.type === bannerTypes.error,
            't-warn': config?.type === bannerTypes.warning,
            't-success': config?.type === bannerTypes.success,
          }"
        >
          {{ config.actionText }}
        </p>
      </button>
    }
  </div>
  <div
    *ngIf="useCustomContentColumn"
    class="message-banner__additional-content"
  >
    <ng-content select="[useCustomContentColumn]"></ng-content>
  </div>
</div>
