import { DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { ButtonConfig } from '@zipari/shared-ds-util-button';

import { TimeoutWarningModalConfig } from './shared/modules/timeout-modal/timeout-modal.constants';

export class KeepaliveErrorConfig {
  /** handles redirecting to an internal route */
  route?: string;

  /** handles redirecting to an external link */
  link?: string;

  constructor(options = {}) {
    Object.assign(this, options);
  }
}

export class IdleConfig {
  enabled = false;
  timeToIdle = 1200; // 20 minutes
  timeoutAfterIdle = 300; // 5 minutes
  interrupts: any[] = DEFAULT_INTERRUPTSOURCES;
  redirectAfterLogoutUrl?: string;

  constructor(options = {}) {
    Object.assign(this, options);
  }
}

export class KeepaliveConfig {
  endpoint = 'user/keep-alive/';
  enabled = true;
  interval = 15;
  error: KeepaliveErrorConfig;

  constructor(
    options: KeepaliveConfig = {
      interval: null,
      error: null,
      enabled: null,
      endpoint: null,
    },
  ) {
    Object.assign(this, options || {});

    this.error = new KeepaliveErrorConfig(this.error || {});
  }
}

export class TimeoutConfig {
  idle?: IdleConfig;
  keepalive?: KeepaliveConfig;
  timeoutWarningModal?: TimeoutWarningModalConfig;

  constructor(options: TimeoutConfig = { idle: null, keepalive: null }) {
    this.idle = new IdleConfig(options.idle);
    this.keepalive = new KeepaliveConfig(options.keepalive);
    this.timeoutWarningModal = new TimeoutWarningModalConfig(
      options.timeoutWarningModal,
    );
  }
}

export class googleAnalyticsConfig {
  gTagId: string;
  enableGoogleAnalytics: boolean;
}

export interface SentryConfig {
  dsn: string;
  release: string;
  denyUrls: RegExp[];
}

export class GlobalConfig {
  timeout: TimeoutConfig;
  title: string;
  analytics: {
    googleAnalytics?: googleAnalyticsConfig;
    sentry?: SentryConfig;
    postHog_capture?: {
      apiKey: string;
      apiHost: string;
      capturePosthogEvents: boolean;
    };
  };
  toggleFeatures: {
    enableMultiGroupManagement?: boolean;
    enableClaimAppeals?: boolean;
    editProfileInformationPermission?: {
      enabledForPayerAdmin: boolean;
      enabledForProviderAdmin: boolean;
    };
    enableReplacementUserWithTaxId?: boolean;
    enableCoverageCheckForPregnancyForm?: boolean;
    enableValidationForTaxId?: boolean;
  };
}

export enum IdleStates {
  Not_Idle = 'No longer idle',
  Started = 'Started',
  Timeout = "You've gone idle",
  TimeoutWarning = 'You will time out in ${countdown} seconds',
}

export interface AuthConfig {
  okta: {
    baseUrl: string;
    textLabels: {
      registrationSubmitButton: string;
      rememberUserCheckboxLabel: string;
      passwordResetTitle: string;
      registrationTitle: string;
      loginTitle: string;
      loginRegistrationLink: string;
      loginSubmitButton: string;
    };
    clientId: string;
    pkce: boolean;
    responseType: string;
    redirectUri: string;
    responseMode: string;
    issuer: string;
    postLogoutRedirectUri: string;
  };
  content: {
    columns: AuthContentColumn[];
    subtitle: string;
    title: string;
    icon: string;
  };
  login: string;
  idp: string;
  forgot_username: string;
  registration: string;
  splash: string;
  forgot_password: string;
  auth0: {
    loginUrl: string;
    logoutNextUrl: string;
  };
}

export interface AuthContentColumn {
  description: string;
  button: ButtonConfig;
  label: string;
}

export const VALID_ROLES: string[] = [
  'Provider Admin',
  'Payer Admin',
  'Provider Office Staff',
  'Provider Financial Office Staff',
];

export enum UserRole {
  providerAdmin = 'Provider Admin',
  payerAdmin = 'Payer Admin',
  ProviderOfficeStaff = 'Provider Office Staff',
  providerFinancialOfficeStaff = 'Provider Financial Office Staff',
}

export enum AuthIDP {
  okta = 'okta',
  auth0 = 'auth0',
  zipari = 'zipari',
}

export enum ProviderPortalManageUserDetails {
  portalUser = 'portal-user',
  accessRequest = 'access-request',
  providerSearchAdmin = 'provider-search-admin',
}

export const documentApiEndpoint = '/api/provider-portal/documents/';
