import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { checkInputsForText } from '../../design-system.helper';

export interface PaginatorConfiguration {
  rowsPerPage?: number;
  showingStart: number;
  showingEnd: number;
  totalResults: number;
  totalPages: number;
  currentPage: number;
  labels?: {
    singular: string;
    plural: string;
  };
}

@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  @Input() config: PaginatorConfiguration;
  @Input() data: any[];
  @Output() pageSelected = new EventEmitter<any>();

  ngOnInit(): void {
    const [config, data] = checkInputsForText([this.config, this.data]);

    this.config = config as PaginatorConfiguration;
    this.data = data as any[];
  }

  setCurrentPage(page: number) {
    this.pageSelected.emit(page);
  }
}
