import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {
  cloneObject,
  deepMerge,
  deepMergeIncludingNulls,
  getValue,
  isInEnum,
} from '@zipari/web-utils';
import { Observable } from 'rxjs';
import { ApiListResponse } from '../models/shared/ApiListResponse.model';
import {
  ConfigApiResponse,
  nonMarketSegmentConfigs,
  validBrokerPortalMarketSegments,
  validShoppingMarketSegments,
} from '../models/shared/config.model';
import Member from '../models/shared/Member.model';
import { RolePermissions } from '../models/shared/RolePermissions.model';

@Injectable()
export class ConfigService {
  public initialConfigResponse;
  public appRoute = 'provider-portal';
  public app: string;
  public tenant: string;
  public configs: any;
  public permissions: RolePermissions = {};
  public activeRoute;
  public activeRouteSegmentForLogo;
  public member: Member;
  public newMarketSegment: any;
  private readonly configEndpoint = 'api/admin/frontend/';

  constructor(private http: HttpClient) {}

  public getMember(): Member {
    return this.member;
  }

  public setMember(member: Member) {
    this.member = member;
  }

  public getMarketSegment(route: ActivatedRouteSnapshot) {
    return getValue(
      route,
      'root.children.0.children.0.children.0.data.marketSegment',
    );
  }

  public getConfig(): Observable<ConfigApiResponse> {
    return this.http.get<ConfigApiResponse>('config');
  }

  /*
        CONFIG here is a global variable provided by django-frontend
        This config comes from config-services for a given app and tenant
    */
  public getPreloadedConfig(): ConfigApiResponse {
    return CONFIG;
  }

  // Get config object as promise needed for Angular's APP_INITIALIZER
  public getPreloadedConfigPromise(): Promise<ConfigApiResponse> {
    return new Promise((resolve, reject) => {
      resolve(this.getPreloadedConfig());
      reject(new Error('something went wrong!'));
    });
  }

  public initializeDBConfigWithLocalOverride(
    appName: string,
    { setConfig = true, makeAPICall = false },
  ): Promise<ConfigApiResponse> {
    const configPromise: Promise<ConfigApiResponse> = !makeAPICall
      ? this.getPreloadedConfigPromise()
      : this.getConfig().toPromise();

    return configPromise.then((configResponse: ConfigApiResponse) => {
      const localConfig = this.getConfigJSON();
      const emptyConfiguration: ConfigApiResponse = new ConfigApiResponse();

      let finalAppConfig = cloneObject(configResponse?.APP);

      finalAppConfig = deepMergeIncludingNulls(
        finalAppConfig,
        localConfig[appName],
      );
      if (configResponse) {
        configResponse.APP = finalAppConfig;
      }

      if (setConfig) {
        return this.setConfig(configResponse);
      }

      return emptyConfiguration;
    });
  }

  public initializeConfig(setConfig = true): Promise<any> {
    const configPromise = this.getPreloadedConfigPromise();

    return configPromise.then((response: any) => {
      // set unless overridden
      if (setConfig) {
        return this.setConfig(response);
      }

      return configPromise;
    });
  }

  public getConfigJSON(): any {
    try {
      return require('../../provider-portal/provider-portal.config.json');
    } catch {
      return {};
    }
  }

  public initializeLocalConfig(): Promise<ConfigApiResponse> {
    const localConfig = this.getConfigJSON();

    this.configs = localConfig;

    return Promise.resolve(localConfig);
  }

  public initializeAllConfig(): Promise<any> {
    const promise = Promise.all<ConfigApiResponse>([
      this.initializeLocalConfig(),
      this.initializeConfig(false),
    ]);

    return promise.then((_response: [ConfigApiResponse, ConfigApiResponse]) => {
      const response = <ConfigApiResponse>deepMerge(..._response);

      return this.setConfig(response);
    });
  }

  private setConfig(data: ConfigApiResponse): Promise<any> {
    this.initialConfigResponse = data;
    this.app = data?.APP_NAME;
    this.tenant = data?.TENANT_NAME;

    const configPromise = this.handleOverrideOfDefaultConfig(
      data?.APP,
      this.app,
    );

    configPromise.then((config: any) => {
      this.configs = config;
      this.permissions = this.configs['role-permissions'];
    });

    return configPromise;
  }

  public handleOverrideOfDefaultConfig(
    overrideConfig: any,
    appName: string,
  ): Promise<any> {
    return this.importAppConfigJson(appName).then((module: any) => {
      const configJson = module?.default;
      let finalAppConfig = cloneObject(configJson);

      finalAppConfig = deepMergeIncludingNulls(finalAppConfig, overrideConfig);

      return finalAppConfig;
    });
  }

  /*
        Handles dynamic import of app-specific config file
    */
  public importAppConfigJson(appName: string): Promise<any> {
    // if (this.tenant === 'democare' || this.tenant === undefined) return import('./../../provider-portal/provider-portal.config.json');
    // else
    /*
            We don't need this any more. It's not touched in local when run through proxy.
            For all live environments, we should just use admin console base as the source of truth
            Commenting out the above will have that effect.
        */
    return new Promise<void>((resolve: any) => resolve());
  }

  public getPageConfig<T>(page: string): T {
    // nonMarketSegmentConfigs contains items in the configuration that should be pulled from the root of the configuration
    if (
      isInEnum(nonMarketSegmentConfigs, page) ||
      'session-expired' === page ||
      !this.activeRoute
    ) {
      return this.configs ? cloneObject(this.configs[page]) : null;
    } else if (this.activeRoute) {
      return this.configs
        ? cloneObject(this.configs[this.activeRoute][page])
        : null;
    } else {
      return <T>{};
    }
  }

  checkIfMarketSegmentIsProvidedInConfig(marketSegment) {
    let inEnum;
    let keys;
    const relevantConfig = this.configs[marketSegment];

    switch (this.app) {
      case 'zshop':
        inEnum =
          isInEnum(validBrokerPortalMarketSegments, marketSegment) ||
          marketSegment === 'large-group' ||
          marketSegment === 'small-group';
        keys = Object.keys(validBrokerPortalMarketSegments);
        keys.push('large-group');
        keys.push('small-group');

        if (relevantConfig && inEnum) {
          return true;
        } else if (!relevantConfig && inEnum) {
          for (let i = 0; i < keys.length; i++) {
            if (this.configs[keys[i]]) {
              this.newMarketSegment = keys[i];
            }
          }

          return false;
        } else {
          return true;
        }

      case 'broker-portal':
        inEnum =
          isInEnum(validBrokerPortalMarketSegments, marketSegment) ||
          marketSegment === 'large-group' ||
          marketSegment === 'small-group';
        keys = Object.keys(validShoppingMarketSegments);
        keys.push('small-group');

        if (relevantConfig && inEnum) {
          return true;
        } else if (!relevantConfig && inEnum) {
          for (let i = 0; i < keys.length; i++) {
            if (this.configs[keys[i]]) {
              this.newMarketSegment = keys[i];
            }
          }

          return false;
        } else {
          return true;
        }
    }
  }

  validateMarketSegment(route: ActivatedRouteSnapshot) {
    const marketSegment = this.getMarketSegment(route);

    if (marketSegment) {
      const valid =
        this.marketSegmentIsValidInApp(route) &&
        this.checkIfMarketSegmentIsProvidedInConfig(marketSegment);

      if (!valid && !!this.newMarketSegment) {
        return false;
      }
    }

    return true;
  }

  marketSegmentIsValidInApp(route: ActivatedRouteSnapshot) {
    switch (this.app) {
      case 'zshop':
        return (
          isInEnum(
            validBrokerPortalMarketSegments,
            this.getMarketSegment(route),
          ) ||
          this.getMarketSegment(route) === 'large-group' ||
          this.getMarketSegment(route) === 'small-group'
        );
      case 'broker-portal':
        return (
          isInEnum(
            validBrokerPortalMarketSegments,
            this.getMarketSegment(route),
          ) ||
          this.getMarketSegment(route) === 'large-group' ||
          this.getMarketSegment(route) === 'small-group'
        );
    }
  }

  public getAppConfigs(
    tenant: string,
    application: string,
  ): Observable<ApiListResponse<any>> {
    const params = new HttpParams()
      .set('app_name', application)
      .set('tenant_name', tenant);

    return this.http.get<ApiListResponse<any>>(this.configEndpoint, {
      params: params,
    });
  }

  public updateConfig(configuration: any): Observable<any> {
    delete configuration['version_number'];

    return this.http.put<any>(
      `${this.configEndpoint}/${configuration.id}/`,
      configuration,
    );
  }

  public patchConfig(configuration: any): Observable<any> {
    delete configuration['version_number'];

    return this.http.patch<any>(
      `${this.configEndpoint}${configuration.id}/`,
      configuration,
    );
  }

  public postConfig(configuration: any): Observable<any> {
    return this.http.post<any>(this.configEndpoint, configuration);
  }
}
